import Vue from 'vue'
import i18n from "@/i18n/i18n"
import { 
  apiGetBoviaLprEventsBasic, 
  apiGetBoviaLprEventsBasicCount,
  apiGetBoviaLprEventsAdvanced,
  apiGetBoviaLprEventsAdvancedCount, 
  apiBoviaLprEventsBasicReport,
  apiBoviaLprEventsAdvancedReport,
  apiGetBoviaLprEventsReports,
} from '@/api/index.js'
import crypto from '@/utils/crypto'
import { handleError } from '@/utils/handleError.js'

const initialState = () => ({
  searchMode: 'basic', // 搜尋模式：一般搜尋(basic), 進階搜尋(advanced)
  queryParams: {}, // 取得歷史事件參數
  searchEventLoading: false,
  beforeAfterId: null, // 記錄目前分頁before event id & after event id
  /** history **/
  eventTotal: 0, // 搜尋的總事件數
  currentPage: 1, // 事件列表目前分頁所在頁數
  pageSize: 100, // 事件列表每個分頁事件數量
  eventList: [], // 僅存放該次搜尋的事件ex:100筆
  currentIndex: 0, // 歷史事件跳窗事件index
  focusIndex: -1, // 點擊歷史事件卡片的index，初始值設為-1, 為了watch其變為０時也能更新focus
  videoId: null, // 歷史追車事件videoId
  eventPlate: '', // 顯示相關歷史紀錄設定此車牌號碼，進入/historty頁面若此值不為空則自動進行搜尋
  queryError: '', // 搜尋錯誤訊息
  
  /** Export Center **/
  showExportPanel: false, // 是否顯示匯出中心面板(位於LeftBar)
  exportMap: {},
  exportList: [],
  showExportInfoModal: false, // 是否顯示匯出已達上限 / 自訂檔名的提示
  exportInfoMode: 'filename', // warning: 匯出已達上限 / filename: 自訂檔名
  isReadyBasicExport: false, // 是否可進行一般查詢的匯出，按匯出後要設為false，重新查詢後才能再次匯出
  isReadyAdvancedExport: false, // 是否可進行進階查詢的匯出，按匯出後要設為false，重新查詢後才能再次匯出

  // 暫存最新三筆案由、車號於localStorage
  queryCauses: localStorage.getItem('queryCauses') ? JSON.parse(crypto.decrypt(localStorage.getItem('queryCauses'))) : [], // 案由
  queryNumber: localStorage.getItem('queryNumber') ? JSON.parse(crypto.decrypt(localStorage.getItem('queryNumber'))) : [], // 車號
})

const state = initialState()

const getters = {
  currentEvent(state) {
    return state.eventList[state.currentIndex]
  },
  totalEventNo(state) {
    // 目前事件在所有事件的編號，從１開始
    return (state.currentPage - 1) * state.pageSize + state.currentIndex + 1
  }
  // totalPages(state) {
  //   return Math.ceil(state.eventTotal / state.pageSize)
  // }
}

const mutations = {
  resetState(state) {
    const initial = initialState()
    Object.keys(initial).forEach(key => { state[key] = initial[key] })
  },
  updateSearchMode(state, value) {
    state.searchMode = value
  },
  updateQueryParams(state, objParams) {
    state.queryParams = objParams
  },
  updateSearchEventLoading(state, value) {
    state.searchEventLoading = value
  },
  updateBeforeAfterId(state, payload) {
    state.beforeAfterId = payload
  },
  updateEventTotal(state, value) {
    state.eventTotal = value
  },
  updateCurrentPage(state, value) {
    state.currentPage = value
  },
  clearEventList(state) {
    state.eventList = []
  },
  updateEventList(state, list) {
    state.eventList = [...list]
    // add uid for each event
    state.eventList.forEach(event => {
      event.uid = `lpr-${event.id}`
    })
  },
  updateCurrentIndex(state, value) {
    state.currentIndex = value
  },
  updateFocusIndex(state, value) {
    state.focusIndex = value
  },
  updateVideoId(state, value) {
    state.videoId = value
  },
  updateEventPlate(state, value) {
    state.eventPlate = value
  },
  updateQueryError(state, value) {
    state.queryError = value
  },
  updateShowExportPanel(state, value) {
    state.showExportPanel = value
  },
  updateExportMap(state, data) {
    const key = Object.keys(data)[0]
    if (!state.exportMap[key]) {
      state.exportMap[key] = data[key]
    } else {
      const list = data[key]
      // 下載的報告根據expiredTime進行排序，較新的排在上面
      list.sort((a, b) => {
        const timeA = new Date(a.expiredTime).getTime()
        const timeB = new Date(b.expiredTime).getTime()
        return (timeA > timeB) ? -1 : (timeA < timeB) ? 1 : 0
      })
      state.exportMap[key] = list
    }
    // console.log(`[updateExportMap] exportMap:`, state.exportMap)
  },
  updateExportList(state /*, payload*/) {
    const keys = Object.keys(state.exportMap)
    let list = []
    for (let key of keys) {
      list = list.concat(state.exportMap[key])
    }
    state.exportList = list
  },
  updateShowExportInfoModal(state, payload) {
    state.showExportInfoModal = payload.isShow
    state.exportInfoMode = payload.mode ? payload.mode : 'filename' 
  },
  updateIsReadyBasicExport(state, value) {
    state.isReadyBasicExport = value
  },
  updateIsReadyAdvancedExport(state, value) {
    state.isReadyAdvancedExport = value
  },
  addQueryCause(state, cause) {
    let idx = state.queryCauses.findIndex(item => item === cause.trim())
    if (idx === -1) {
      state.queryCauses.unshift(cause.trim())
      state.queryCauses = state.queryCauses.slice(0, 3) // 暫存最新三筆
      localStorage.setItem('queryCauses', crypto.encrypt(JSON.stringify(state.queryCauses)))
    }
  },
  addQueryNumber(state, number) {
    let idx = state.queryNumber.findIndex(item => item === number.trim())
    if (idx === -1) {
      state.queryNumber.unshift(number.trim())
      state.queryNumber = state.queryNumber.slice(0, 3) // 暫存最新三筆
      localStorage.setItem('queryNumber', crypto.encrypt(JSON.stringify(state.queryNumber)))
    }
  }
}

const actions = {
  async searchEvents({ state, commit, dispatch }, payload) {
    commit('updateSearchEventLoading', true)
    commit('updateQueryError', '')
    if (state.queryParams.purpose)
      commit('addQueryCause', state.queryParams.purpose) // 將案由暫存至localStorage
    if (state.queryParams.content) 
      commit('addQueryNumber', state.queryParams.content) // 將車號暫存至localStorage
    let bGoPrev = false
    let param = state.queryParams
    if (!state.beforeAfterId) {
      commit('updateCurrentIndex', 0)
      commit('updateFocusIndex', -1)
      commit('updateCurrentPage', 1) // 重新搜尋切換到分頁１
    } else {
      if (payload.page > state.currentPage) {
        delete param.after
        param.before = state.beforeAfterId.before
      } else {
        delete param.before
        param.after = state.beforeAfterId.after
        bGoPrev = true
      } 
    }
    
    // 因為進階搜尋中, missioned, missionCodes, executed為陣列，所以需要將搜尋的參數再處理
    let urlSearchParams = new URLSearchParams()
    if (state.searchMode === 'advanced') {
        Object.entries(param).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach(item => {
              urlSearchParams.append(key, item)
            })
          } else
            urlSearchParams.append(key, value)
        })
    }
      
    try {  
      // 取得事件
      let res = {}
      if (state.searchMode === 'basic') {
        if (payload.page === 1 && !bGoPrev) 
          dispatch('getEventsBasicCount', param) // 取得事件總數(當取第一頁並且不是回上頁，否則不呼叫)
        res = await apiGetBoviaLprEventsBasic(param)
      }

      if (state.searchMode === 'advanced') {
        if (payload.page === 1 && !bGoPrev) 
          dispatch('getEventsAdvancedCount', urlSearchParams) // 取得事件總數(當取第一頁並且不是回上頁，否則不呼叫)
        res = await apiGetBoviaLprEventsAdvanced(urlSearchParams)
      } 

      commit('updateFocusIndex', -1) // 重新搜尋時，focusIndex先reset到-1，避免focusIndex相同值時，不會觸發watch
      commit('updateEventList', [])
      const len = res.data?.length
      if (len > 0) {
        // 若用after取資料id會從小到大，須先排序
        res.data.sort((x, y) => y.id - x.id)
        // 資料id由大到小
        commit('updateBeforeAfterId', { 
          before: res.data[len - 1].id, 
          after: res.data[0].id 
        })
        commit('updateEventList', res.data)
        commit('updateCurrentPage', payload.page)

        // 若是回到前一分頁，eventIndex更新為前一分頁最後一筆，下一頁則是第一筆
        let eventIndex = bGoPrev ? len - 1 : 0 
        commit('updateCurrentIndex', eventIndex)
        commit('updateFocusIndex', eventIndex)  
      } else {
        Vue.prototype.$notify.warning({
          title: i18n.t('search_hint')/*提示*/,
          message: i18n.t('search_hint_nodata')/* 查無資料 */
        })
      }
    } catch (error) {
      dispatch('handleTimeout', error)
      handleError(error)

      commit('updateEventList', [])
      commit('updateEventTotal', 0)
    } finally {
      commit('updateSearchEventLoading', false)
    }
  },
  handleTimeout({ commit }, error) {
    if (error.code === 'ECONNABORTED') {
      // 搜尋逾時, 請縮小搜尋範圍
      const msg = `${i18n.t('search_timeout_title')}，${i18n.t('search_timeout_message')}`
      commit('updateQueryError', msg)
    }
  },
  async getEventsBasicCount({ state, commit, dispatch }, params) {
    try {
      commit('updateEventTotal', 0)
      const res = await apiGetBoviaLprEventsBasicCount(params)
      commit('updateEventTotal', res?.data?.total ? res.data.total : state.historyEventsCount)
    } catch (error) {
      dispatch('handleTimeout', error)
      console.log('apiGetBoviaLprEventsBasicCount error: ', error)
    }
  },
  async getEventsAdvancedCount({ state, commit, dispatch }, params) {
    try {
      commit('updateEventTotal', 0)
      const res = await apiGetBoviaLprEventsAdvancedCount(params)
      commit('updateEventTotal', res?.data?.total ? res.data.total : state.historyEventsCount)
    } catch (error) {
      dispatch('handleTimeout', error)
      console.log('apiGetBoviaLprEventsAdvancedCount error: ', error)
    }
  },
  async exportBasicReport({ state, commit }, payload) {
    if (state.searchMode !== 'basic') return

    const queryParams = { ...state.queryParams }
    if (queryParams.before) delete queryParams.before
    if (queryParams.after) delete queryParams.after
    if (queryParams.deviceName) delete queryParams.deviceName
    if (queryParams.user) queryParams.user = [queryParams.user]

    const data = { ...payload, query: queryParams }
    await apiBoviaLprEventsBasicReport(data)
    commit('updateIsReadyBasicExport', false) // 不能重複匯出
    commit('updateShowExportPanel', true) // 打開匯出中心面板
    // 重新取報告列表
    const res = await apiGetBoviaLprEventsReports()
    commit('updateExportList', res.data)
  },
  async exportAdvancedReport({ state, commit }, payload) {
    if (state.searchMode !== 'advanced') return

    const queryParams = { ...state.queryParams }
    if (queryParams.before) delete queryParams.before
    if (queryParams.after) delete queryParams.after

    const data = { ...payload, query: queryParams }
    await apiBoviaLprEventsAdvancedReport(data)
    commit('updateIsReadyAdvancedExport', false) // 不能重複匯出
    commit('updateShowExportPanel', true) // 打開匯出中心面板
    // 重新取報告列表
    const res = await apiGetBoviaLprEventsReports()
    commit('updateExportList', res.data)
  },
  resetHistoryData({ commit }) {
    commit('clearEventList')
    commit('updateEventPlate', '')
    commit('updateCurrentIndex', 0)
    commit('updateFocusIndex', -1)
    commit('updateEventTotal', 0)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
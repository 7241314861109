import { apiGetGroupTree, apiGetGroupList } from '@/api/index.js'
import { apiGetUserList, apiGetWebRTCRooms } from '@/api/v4.js'
import { getFilterUserTreeList } from '@/utils/lib.js'
import { aicamDeviceModelId } from '@/config/account'

const initialState = () => ({
  groupTree: {},
  groupList: [],
  deviceList: [],
  deviceTreeList: [], // 帶public參數的device tree list
  userList: [],
  userTreeList: [],
  privateDeviceList: [], // private device list (public: 0)
  aicamFamily: [],
  onCallMembers: [], // 目前通話中的成員
})

const state = initialState()

const mutations = {
  updateDeviceList(state, payload) {
    state.deviceList = payload
  },
  updateGroupTree(state, payload) {
    state.groupTree = payload
  },
  updateGroupList(state, payload) {
    state.groupList = payload
  },
  updateDeviceTreeList(state, payload) {
    state.deviceTreeList = payload
  },
  updateUserList(state, payload) {
    state.userList = payload
  },
  updateUserTreeList(state, payload) {
    state.userTreeList = payload
  },
  updatePrivateDeviceList(state, payload) {
    state.privateDeviceList = payload
  },
  updateAicamFamily(state, payload) {
    state.aicamFamily = payload
  },
  updateOnCallMembers(state, payload) {
    state.onCallMembers = payload
  },
}

const actions = {
  async getDeviceList({ commit }, { enabled = null, public: pub = null }) {
    const res = await apiGetUserList({ kind: 'device', enabled, public: pub })
    commit('updateDeviceList', res.data)
  },
  async getGroupTree({ commit }) {
    const res = await apiGetGroupTree()
    commit('updateGroupTree', res.data)
  },
  async getDeviceTreeList({ commit, state }, { enabled = null, public: pub = null }) {
    const resDevices = await apiGetUserList({ kind: 'device', enabled: enabled,  public: pub })
    const resTree = await apiGetGroupTree()
    const resGroupList = await apiGetGroupList()

    commit('updateDeviceList', resDevices.data)
    commit('updateGroupTree', resTree.data)
    commit('updateGroupList', resGroupList.data)

    const treeList = getFilterUserTreeList(state.deviceList, state.groupTree)
    commit('updateDeviceTreeList', treeList)
  },
  async getUserTreeList({ commit, state }, { enabled = null, public: pub = null }) {
    const resUsers = await apiGetUserList({ kind: 'user', enabled, public: pub })
    const resTree = await apiGetGroupTree()
    const resGroupList = await apiGetGroupList()

    commit('updateUserList', resUsers.data)
    commit('updateGroupTree', resTree.data)
    commit('updateGroupList', resGroupList.data)

    const treeList = getFilterUserTreeList(state.userList, state.groupTree)
    commit('updateUserTreeList', treeList)
  },
  async getPrivateDeviceList({ commit }) {
    const resDevices = await apiGetUserList({ kind: 'device', enabled: 1, public: 0 }) // public: 0
    commit('updatePrivateDeviceList', resDevices.data)
  },
  async getAccountTreeData({}, { kind = null, enabled = null, public: pub = null }) {
    const resUsers = await apiGetUserList({ kind, enabled, public: pub })
    const resTree = await apiGetGroupTree()

    const treeList = getFilterUserTreeList(resUsers.data, resTree.data)
    return {
      groupTree: resTree.data,
      accountList: resUsers.data,
      accountTreeList: treeList
    }
  },
  async getOnCallMembers({ commit }) {
    const res = await apiGetWebRTCRooms()
    const roomList = res.data.rooms
    commit('updateOnCallMembers', roomList.map((room) => room.members).flat())
  },
  async checkIfUserIsOnCall({ dispatch, state }, userIndex) {
    await dispatch('getOnCallMembers')
    return state.onCallMembers.some((m) => m.id === String(userIndex))
  }
}

const getters = {
  getAccountName: (state) => (userId) => {
    return state.deviceList.find(user => user.id === userId)?.info.name
  },
  getUserGroupId: (state) => (userId) => {
    return state.deviceList.find(user => user.id === userId)?.groupId
  },
  getDeviceTitleId: (state) => (userId) => {
    const device = state.deviceList.find(user => user.id === userId)
    return device ? `${device.video.title} (${device.id})` : `(${userId})`
  },
  getDeviceModelId: (state) => (userId) => {
    const device = state.deviceList.find(user => user.id === userId)
    return device ? device.deviceModelId : null
  },
  getUserTitleId: (state) => (userId) => {
    const user = state.userList.find((item) => item.id === userId)
    return user ? `${user.video.title} (${user.id})` : `(${userId})`
  },
  isAicam: (state) => (userId) => {
    const device = state.deviceList.find(user => user.id === userId)
    return device ? aicamDeviceModelId.includes(device.deviceModelId) : false
  },
  getAicamFamily: (state) => (userId) => {
    const family = state.aicamFamily.find(family => 
      family.mainDevices.find(d => d.id === userId) ||
      family.subDevices.find(d => d.id === userId))
    
    return family
  },
  // userId 是否為 Aicam 子帳號且有父帳號
  hasAicamFather: (state, getters) => (userId) => {
    const family = getters.getAicamFamily(userId)
    return family && family.mainDevices.length > 0 && family.subDevices.find(d => d.id === userId)
  },
  getConnectedAicamFather: (state, getters, rootState) => (userId) => {
    if (!getters.hasAicamFather(userId)) return null

    const family = getters.getAicamFamily(userId)
    // 若有多個父帳號時，找出有在 connectionList(webrtc > 0) 的那個
    const fatherDevice = family.mainDevices.find(mainDevice =>  
      rootState.connectionList.find(conn => 
        conn.userAccount === mainDevice.id)?.webrtc > 0)
    return fatherDevice
  },
  canCall: (state, getters, rootState) => (userId) => {
    if (!userId) return false

    const lUser = rootState.liveList.find((u) => u.id === userId)
    const cUser = rootState.connectionList.find((u) => u.userAccount === userId)

    // 離線設備不能"一對一通話"
    if (!cUser && !lUser) return false

    // 若設備為 aicam 子帳號，則檢查是否有可撥打的父帳號以及是否通話中
    if (getters.hasAicamFather(userId)) {
      const aicamFatherDevice = getters.getConnectedAicamFather(userId)
      if (!aicamFatherDevice) return false
      return !state.onCallMembers.some((m) => m.id === String(aicamFatherDevice.index)) // 父帳號是否在通話中
    }

    // 檢查設備是否已經在通話中
    const device = state.deviceList.find((item) => item.id === userId)
    if (state.onCallMembers.some((m) => m.id === String(device?.index))) return false

    if (cUser) return cUser.webrtc >= 0

    // 有些較舊的設備沒有 connection, 則判斷是否有 Live
    return lUser ? true : false
  },
  isOnCall: (state, getters) => (deviceId) => {
    if (!deviceId) return false

    const onCallIds = state.onCallMembers.map((m) => String(m.id))
    
    // 若有 Aicam 父帳號，則檢查父帳號是否在通話中
    if (getters.hasAicamFather(deviceId)) {
      const aicamFatherDevice = getters.getConnectedAicamFather(deviceId)
      return aicamFatherDevice ? onCallIds.includes(String(aicamFatherDevice.index)) : false
    } else {
      const device = state.deviceList.find((item) => item.id === deviceId)
      return device ? onCallIds.includes(String(device.index)) : false
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
import Vuex from 'vuex'
import Vue from 'vue'
import createPersistedState from 'vuex-persistedstate'
import moment from 'moment-timezone'
import axios from 'axios'
import crypto from '@/utils/crypto.js'
import router from '@/router/index.js'

import account from './modules/account.js'
import global from './modules/global.js'
import deviceCtrl from './modules/deviceCtrl.js'
import history from './modules/history/index.js'
import userinfo from './modules/userinfo.js'
import aibox from './modules/aibox.js'
import recgFr from './modules/recgFr.js'
import recgLpr from './modules/recgLpr.js'
import recgOr from './modules/recgOr.js'
import setting from './modules/setting.js'
import video from './modules/video.js'
import frDb from './modules/frDb.js'
import {
  apiCheckStatus,
  apiErrorMsg,
  base_apiurl,

  // API Path
  apiLogout,
  apiGetWebPreferences, 
  apiGetWebAnnouncements, 
  apiGetUser, 
  apiGetGroupList, 
  apiGetSettingGeneral, 
  apiGetTagList, 
  apiGetFrTagList,
  apiGetBoviaLprCodebooks, 
  apiGetVideoGPS,
  apiGetSosEventsDashboard,
} from '@/api/index.js'
import {
  apiGetUserList,
  apiGetV4ServerSetting,
} from '@/api/v4.js'
import i18n from '@/i18n/i18n.js'
import { supportLangs, getLang } from '@/i18n/i18n'
import {
  euForced2fa,
  sysCustomize,
  getCustomizeValue,
  // chkCustomizeFuncPark,
  euVideoDisplayMode,
  euMapType,
} from '@/config/others.js'

Vue.use(Vuex)

const initialState = () => ({
  isLogin: false,
  accessToken: null,
  isDemo: process.env.VUE_APP_DEMO ? true : false, // 需要 demo 再打開, 需要 package.json 配合使用
  autoSelectAllDevices: true, // 是否自動選取所有設備(勾選所有設備清單中的設備>>關注清單)
  watchUsers: [], // 關注清單設備
  selectedUsers: [], // 關注清單中勾選的設備
  dragUser: {},
  trackUser: {},
  userList: [], // 所有啟用中帳號
  userFullList: [], // 所有帳號, 不分啟用/停用
  groupList: [],
  groupTree: {},
  lprEventList: [],
  frEventList: [],
  orEventList: [],  // 物件事件
  sosEventList: [],
  parkingEventList: [], // 停車場事件
  liveList: [],
  codeBooks: {},
  tagList: [],
  frTagList: [],
  deviceTabIdx: 0, // 0: 全部, 1: Online, 2: Live 關注清單 TabIndex
  showCheckedAll: false,   // 關注清單(全部)已勾選開關
  showCheckedOnline: false, // 關注清單(上線)已勾選開關
  showCheckedLive: false, // 關注清單(直播)已勾選開關
  showFilter: true, // 關注清單顯示過濾器
  connectionList: [],
  sosConnectionList: [],
  markerGpsList: [],
  user: '',
  userFlatObj: {},
  filteredDisplayEvents: [], // 顯示事件列表
  // menuOpen
  LeftMenuOpen: false,
  RightMenuOpen: false,
  TopMenuHeightLevel: 1,
  videoViewMode: 0, // Video視窗顯示模式：0 (1,2,3,4,5,6,7)
  isHoverTop: false,
  isHoverRight: false,
  // event detail modal open
  showEventCardDetail: false,
  eventCardDetailObj: {},
  // groupcall mode: off/on/calling
  callMode: 'off', // off / calling-group / calling-user
  callCmd: null, // 讓 VideoPlayer & MultipleView.Video 透過監聽 callCmd, 處理 關閉/開啟 影像音量的toast
  LiveEventOn: false,
  liveMode: true, // 單路video播放模式 true: 直播live video; false: 歷史影片
  matchLiveOrigin: '',
  liveVideoUrl: '',
  lockDeviceId: '', // 當有值時表示只鎖定某一設備，事件列表只顯示該設備事件; 否則顯示所有勾選帳號的設備
  singleUrlUserID: null,
  showAllowAudio: true, // reload時需跳窗詢問，切換頁面則不需要再顯示將值設為false

  /** video gpsList, marker ＆ path資料 */
  videoGpsList: [], // 紀錄video GPS位置資料
  videoMarker: {},
  videoPath: [], // video軌跡

  /** permission **/
  // lprDashboard // 0: 無法進入dashboard, 1: 可顯示dashboard, 2: 可顯示非資料庫辨識事件
  // lprEventAccess // 0: 不能進入history, 1: 一般搜尋, 2: 績效統計
  // lprSummary // 0: 不能進入車輛管理, 1: 可進入車輛管理
  // roleManagement // 1: 可進入帳號管理,
  // account  // 1: 可進入帳號管理,
  permissionV2: {},
  ssoLogin: false,
  notAutomaticLogout: false, // [Dashboard] 防止閒置自動登出
  showMapOnlineDevices: false, // [Dashboard] 地圖是否只顯示上線設備
  showFullTitle: true, // [Dashboard] 直播影像是否顯示完整標題
  showAccountInTitle: true, // [Dashboard] 直播影像是否顯示帳號(user.id)
  showLiveRecognitionFrame: false, // [Dashboard] Live 影像上, 是否顯示辨識框
  videoFill: false, // [Dashboard] 直播影像是否全填滿
  showAccountInDeviceName: true, // [Dashboard] 設備列表與地圖設備是否顯示帳號(user.id)
  staff: 0,
  systemLanguage: '', // 全域設定：語言
  systemTimezone: '', // 全域設定：時區
  customize: getCustomizeValue(sysCustomize.bovia), // 全域設定：客戶
  '2faMode': euForced2fa.off, // 客製網頁

  /** v4 system setting **/
  // allowParkingUnit: 'p',
  isParking: false,

  /** Web preferences **/
  idleTimeout: 20, // minutes, 閒置未用自動登出時間
  systemName: '5G-AI 巡防系統',
  logo: '',
  banner: '',
  bgWatermark: 0,
  favicon: '',
  bannerDashboard: '',
  appDownload: 1, // 顯示APP下載連結 0:off, 1: on
  manualDownload: 1, // 顯示使用手冊載點 0: off, 1: on
  // customize_func: [], // 客製功能
  frPhotoRegisterCount: 0, // 人物辨識註冊圖張數限制
  map_type: -1, // 地圖類型

  isGetWebPreferences: false,

  showDownloadPanel: false, // 顯示下載中心

  /** Web Announcement **/
  webAnnouncements: [],

  /** visibilitystate **/
  isPageVisible: true, // 網頁是否位於前景或背景
  rightClickUser: {}, // 設備列表右鍵點擊帳號的資訊
  showAccountInfoModal: false, // 顯示帳號資訊跳窗

  /** Dashboard 事件列表顯示與提示設定 **/
  eventDisplaySetting: {
    lpr: {
      match: { display: true, tags: [], sound: false, flash: false },
      noMatch: { display: false, sound: false, flash: false },
    },
    fr: {
      match: { display: true, tags: [], sound: false, flash: false },
      noMatch: { display: false, sound: false, flash: false },
    },
    or: { 
      display: true, category: [], sound: false, flash: false 
    },
    parking: {
      general: { display: true, sound: false, flash: false },
      vip: { display: true, sound: false, flash: false },
      blacklist: { display: true, sound: false, flash: false },
      unknown: { display: true, sound: false, flash: false },
    },
  },
  
  latestEventId: { // 紀錄最新事件ID
    lpr: 0,
    fr: 0,
    or: 0,
    parking: 0,
  },

  /** Map **/
  mapType: '', // 地圖類型: terrain, hybrid, (roadmap, satellite)
  
})

const state = initialState()

const mutations = {
  login(state, boolVal) {
    state.isLogin = boolVal
  },
  setAccessToken(state, token) {
    state.accessToken = token
  },
  updateSingleUrlUserID(state, id) {
    // 點擊left、right、top bar 就重新assign選取到的使用者ID
    // watch ID：有變動就重新更改left、right選中的使用者，bottom重新以該ID為中心定位
    // 取得ID後 單路component會根據此ID取得video url => 有直播就放直播 沒直播就放歷史
    state.singleUrlUserID = id
  },
  updateAutoSelectAllDevices(state, boolVal) {
    state.autoSelectAllDevices = boolVal
  },
  updateWatchUsers(state, watchUsers) {
    state.watchUsers = watchUsers
  },
  addWatchUser(state, user) {
    let idx = state.watchUsers.findIndex((item) => item.id === user.id)
    if (idx === -1) {
      state.watchUsers.push(user)
    }
  },
  setSelectedUsers(state, selectedUsers) {
    state.selectedUsers = [...selectedUsers]
  },
  /**
   * 
   * @param {*} state 
   * @param {*} user = {
            id: clickUser.id,
            name: clickUser.video.title,
            groupId: clickUser.groupId,
            label: clickUser.video.title + ' (' + clickUser.id + ')'
          }
   */
  addSelectedUser(state, user) {
    let idx = state.selectedUsers.findIndex((item) => item.id === user.id)
    if (idx === -1) {
      state.selectedUsers.push(user)
    }
  },
  insertSelectedUser(state, payload) {
    let idx = state.selectedUsers.findIndex((item) => item.id === payload.user.id)
    if (idx === -1) {
      state.selectedUsers.splice(payload.insertIndex, 0, payload.user)
    }
  },
  /**
   * 交換dragIndex與dropIndex兩筆資料, 有dragIndex表示在video視窗內互換，若無dragIndex表示由外面拖進來
   * @param {*} dragIndex = { videoTitle: string, dragIndex: int }
   * @param {*} dropIndex 
   */
  swapSelectedUsers(state, payload) {
    // let temp = state.selectedUsers[payload.dragIndex]
    // state.selectedUsers[payload.dragIndex] = state.selectedUsers[payload.dropIndex]
    // state.selectedUsers[payload.dropIndex] = temp

    const { dragIndex, dropIndex } = payload
    const drag = state.selectedUsers[payload.dragIndex]
    const drop = state.selectedUsers[payload.dropIndex]

    state.selectedUsers.splice(dragIndex, 1, drop)
    state.selectedUsers.splice(dropIndex, 1, drag)
  },
  setDragUser(state, dragUser) {
    state.dragUser = JSON.parse(JSON.stringify(dragUser))
  },
  setTrackUser(state, trackUser) {
    state.trackUser = JSON.parse(JSON.stringify(trackUser))
  },
  setUserList(state, userList) {
    let sortUsers = userList.sort((a, b) => a.video.title.localeCompare(b.video.title, i18n.locale))
    state.userList = sortUsers
  },
  setUserFullList(state, userFullList) {
    let sortUsers = userFullList.sort((a, b) => a.video.title.localeCompare(b.video.title, i18n.locale))
    state.userFullList = sortUsers
  },
  updateGroupList(state, groupList) {
    state.groupList = groupList
  },
  setGroupTree(state, payload) {
    state.groupTree = payload
  },
  clearEventList(state, key) {
    const keyList = `${key}EventList`
    state[keyList] = []
  },
  updateEventList(state, { key, newEvents, insertMethod = 'unshift' }) {
    const keyList = `${key}EventList`
    if (!state[keyList]) return

    // 檢查是否有重複事件，只加入新事件
    const eventIds = new Set(state[keyList].map(event => event.id))
    newEvents = newEvents.filter(event => !eventIds.has(event.id))

    newEvents.forEach(event => {
      event.uid = `${key}-${event.id}`
      event.timestamp = new Date(event.detectTime).getTime()  // 後續與其他事件排序使用
    })

    if (insertMethod === 'unshift') {
      state[keyList].unshift(...newEvents)
    } else {
      state[keyList].push(...newEvents)
    }

    // 維持最新1000筆事件，刪除較舊的資料
    if (state[keyList].length > 1000) {
      state[keyList].splice(1000)
    }
  },
  clearSosEventList(state) {
    state.sosEventList = []
  },
  updateSosEventList(state, payload) {
    state.sosEventList = payload
  },
  updateChasingEvent(state, payload) {
    let index = state.lprEventList.findIndex(
      (event) => event.id == payload.id
    )

    if (!payload.uid) payload.uid = `lpr-${payload.id}`
    if (!payload.timestamp) payload.timestamp = new Date(payload.detectTime).getTime()
    if (index >= 0) {
      state.lprEventList.splice(index, 1, payload)
    } else {
      state.lprEventList.unshift(payload)
    }
  },
  updateLiveList(state, liveList) {
    state.liveList = liveList

    // 更新markerGpsList: 取沒有在connectionList中的live，取其gps加入
    const watchUserIds = new Set(state.watchUsers.map(user => user.id))
    state.liveList.forEach(live => {
      if (!watchUserIds.has(live.id)) return // 2024.11.21 fix: 只暫存有在關注清單的帳號的GPS
      let index = state.connectionList.findIndex(conn => conn.userAccount === live.id && conn.gps)
      if (index === -1 && live.gps && (live.gps.lock === 1 || (live.gps.lock === 0 && live.gps.timestamp))) {
        let mkIdx = state.markerGpsList.findIndex(item => item.userAccount === live.id)
        if (mkIdx === -1) {
          let arrGpsData = []
          arrGpsData.push({
            lat: live.gps.latitude,
            lng: live.gps.longitude,
            timestamp: live.gps.timestamp
          })
          state.markerGpsList.push({
            userAccount: live.id,
            deviceType: live.device.info.type,
            gpsData: arrGpsData
          })
        } else {
          state.markerGpsList[mkIdx].deviceType = live.device.info.type
          let pGpsData = state.markerGpsList[mkIdx].gpsData
          pGpsData.push({
            lat: live.gps.latitude,
            lng: live.gps.longitude,
            timestamp: live.gps.timestamp
          })

          if (state.trackUser.id == live.id) {
            // 選定顯示軌跡的帳號
            if (pGpsData.length > 50) pGpsData.shift() // 移除最舊一筆
          } else {
            if (pGpsData.length > 30) pGpsData.shift() // 移除最舊一筆
          }
        }
      }
    })
  },
  updateFilteredDisplayEvents(state, list) {
    state.filteredDisplayEvents = list
  },
  updateLeftMenuOpen(state, sts) {
    state.LeftMenuOpen = sts
  },
  updateRightMenuOpen(state, sts) {
    state.RightMenuOpen = sts
  },
  updateTopMenuHeightLevel(state, level) {
    state.TopMenuHeightLevel = level
  },
  updateVideoViewMode(state, value) {
    // value = 1 單路模式
    // value = 2~4 多路模式
    // value = 5~7 非NxN 多路模式
    if (value > 1) {
      state.LiveEventOn = false
      state.liveVideoUrl = ''
      state.matchLiveOrigin = ''
    }
    state.videoViewMode = value
  },
  updateIsHoverTop(state, sts) {
    state.isHoverTop = sts
  },
  updateIsHoverRight(state, sts) {
    state.isHoverRight = sts
  },
  updateShowEventCardDetail(state, sts) {
    state.showEventCardDetail = sts
  },
  updateEventCardDetailObj(state, obj) {
    state.eventCardDetailObj = obj
  },
  updateLiveEventOn(state, { sts, url, origin }) {
    // 單路 多路 相互切換時 要關掉另一種模式(ex 單路開 多路就關)
    if (sts) {
      state.videoViewMode = euVideoDisplayMode.mode_1x1
    }
    state.LiveEventOn = sts
    state.liveVideoUrl = url
    state.matchLiveOrigin = origin
  },
  updateTagList(state, data) {
    state.tagList = data
  },
  updateFrTagList(state, data) {
    state.frTagList = data
  },
  updateCodeBooks(state, data) {
    state.codeBooks = JSON.parse(JSON.stringify(data))
  },
  updateDeviceTabIdx(state, value) {
    state.deviceTabIdx = value
  },
  updateShowCheckedAll(state, value) {
    state.showCheckedAll = value
  },
  updateShowCheckedOnline(state, value) {
    state.showCheckedOnline = value
  },
  updateShowCheckedLive(state, value) {
    state.showCheckedLive = value
  },
  updateShowFilter(state, value) {
    state.showFilter = value
  },
  updateConnectionList(state, data) {
    state.connectionList = data
    const watchUserIds = new Set(state.watchUsers.map(user => user.id))
    //connections API 中 SOS 欄位 只要 > 0, 就表示有 SOS 事件 (>0 的數字是 SOS 的事件 id)
    // 2024.11.21 修正: 只顯示有在關注清單的帳號發生的SOS事件
    // 2025.02.10 修正: 顯示設備列表(可視範圍內)發生的SOS事件
    state.sosConnectionList = data.filter((conn) => conn.sos > 0)

    // 更新markerGpsList
    state.connectionList.forEach(conn => {
      if (!watchUserIds.has(conn.userAccount)) return  // 2024.11.21 fix: 只暫存有在關注清單的帳號的GPS
      // 2023.09.09 
      // gps.lock=1 要顯示在地圖上
      // gps.lock=0 timestamp有值 要顯示在地圖上
      // gps.lock=0 沒有timestamp 不顯示在地圖上
      if (conn.gps && (conn.gps.lock === 1 || (conn.gps.lock === 0 && conn.gps.timestamp))) {
        let idx = state.markerGpsList.findIndex(
          (item) => item.userAccount == conn.userAccount
        )
        if (idx === -1) {
          let arrGpsData = []
          arrGpsData.push({
            lat: conn.gps.latitude,
            lng: conn.gps.longitude,
            timestamp: conn.gps.timestamp
          })
          state.markerGpsList.push({
            userAccount: conn.userAccount,
            deviceType: conn.deviceAgent,
            gpsData: arrGpsData
          })
        } else {
          state.markerGpsList[idx].deviceType = conn.deviceAgent
          let pGpsData = state.markerGpsList[idx].gpsData
          pGpsData.push({
            lat: conn.gps.latitude,
            lng: conn.gps.longitude,
            timestamp: conn.gps.timestamp
          })

          if (state.trackUser.id == conn.userAccount) {
            // 選定顯示軌跡的帳號
            if (pGpsData.length > 50) pGpsData.shift() // 移除最舊一筆
          } else {
            if (pGpsData.length > 30) pGpsData.shift() // 移除最舊一筆
          }
        }
      }
    })
  },
  updateUserFlatObj(state, data) {
    if (Object.keys(data).length === 0) state.userFlatObj = {}
    else {
      let tempObj = {
        id: data.id,
        index: data.index,
        name: data.info.name,
        groupId: data.groupId,
        groupName: data.groupName,
        roleId: data.roleId,
        roleName: data.roleName,
        email: data.info.email,
        phone: data.info.phone,
        language: data.language,
        timezone: data.timezone,
        lastLogin: data.lastLogin,
        '2faEnabled': data['2faEnabled'],
        '2faMode': (data['2faMode'] !== undefined) ? data['2faMode'] : state.userFlatObj['2faMode'],
        kind: data.kind,
        watermarkEnabled: data.watermarkEnabled
      }

      state.userFlatObj = tempObj
    }
  },
  updateCallMode(state, mode) {
    state.callMode = mode
  },
  updateCallCmd(state, cmd) {
    state.callCmd = cmd
  },
  updateLiveMode(state, value) {
    state.liveMode = value
  },
  updateLockDeviceId(state, deviceId) {
    state.lockDeviceId = deviceId
  },
  updateShowAllowAudio(state, value) {
    state.showAllowAudio = value
  },
  addVideoGpsList(state, list) {
    list.forEach(item => {
      state.videoGpsList.push(item)
    })
  },
  updateVideoGpsList(state, list) {
    state.videoGpsList = [...list]
  },
  updateVideoMarker(state, obj) {
    state.videoMarker = JSON.parse(JSON.stringify(obj))
  },
  clearVideoPath(state) {
    state.videoPath = []
  },
  updateVideoMarkerPath(state, videoTime) {
    if (state.videoGpsList.length === 0) return
    if (state.videoPath.length === 0) {
      state.videoPath.push({
        lat: state.videoGpsList[0].latitude,
        lng: state.videoGpsList[0].longitude
      })

      // 更新marker到最新位置
      state.videoMarker.position.lat = state.videoGpsList[0].latitude
      state.videoMarker.position.lng = state.videoGpsList[0].longitude
      state.videoMarker.position.timestamp = state.videoGpsList[0].timestamp
    } else {
      let num = state.videoPath.length
      for (let i=num; i<state.videoGpsList.length; i++) {
        if (new Date(state.videoGpsList[i].timestamp) <= new Date(videoTime)) {
          state.videoPath.push({
            lat: state.videoGpsList[i].latitude,
            lng: state.videoGpsList[i].longitude
          })

          // 更新marker到最新位置
          let idx = state.videoPath.length - 1
          state.videoMarker.position.lat = state.videoGpsList[idx].latitude
          state.videoMarker.position.lng = state.videoGpsList[idx].longitude
          state.videoMarker.position.timestamp = state.videoGpsList[idx].timestamp
        }  
      }
    }
  },
  updatePermissionV2(state, payload) {
    state.permissionV2 = payload
  },
  updateSsoLogin(state, payload) {
    state.ssoLogin = payload
  },
  updateNotAutomaticLogout(state, value) {
    state.notAutomaticLogout = value // 防止閒置自動登出
  },
  updateShowMapOnlineDevices(state, value) {
    state.showMapOnlineDevices = value
  },
  updateShowFullTitle(state, value) {
    state.showFullTitle = value
  },
  updateShowAccountInTitle(state, value) {
    state.showAccountInTitle = value
  },
  updateShowLiveRecognitionFrame(state, value) {
    state.showLiveRecognitionFrame = value
  },
  updateVideoFill(state, value) {
    state.videoFill = value
  },
  updateShowAccountInDeviceName(state, value) {
    state.showAccountInDeviceName = value
  },
  updateIdleTimeout(state, value) {
    state.idleTimeout = value // 20 minutes
  },
  updateLanguage(state, payload) {
    state.userFlatObj.language = payload
  },
  updateTimezone(state, payload) {
    state.userFlatObj.timezone = payload
  },
  updateStaff(state, payload) {
    state.staff = payload
  },
  updateSystemLanguageTimezone(state, payload) {
    state.systemLanguage = payload.language
    state.systemTimezone = payload.timezone
  },
  updateWebPreferences(state, payload) {
    // console.log(`[Store.updateWebPreferences]`)
    state.idleTimeout = payload.idle_timeout
    // state.idleTimeout = 0.1 // for DEBUG
    state.systemName = payload.system_name
    state.logo = payload.logo
    state.banner = payload.banner
    state.bgWatermark = payload.bgWatermark
    state.favicon = payload.favicon
    state.bannerDashboard = payload.bannerDashboard
    state.appDownload = payload.app_download
    state.manualDownload = payload.manual_download
    state.customize = payload.customize
    state['2faMode'] = payload['2faMode']
    // state.customize_func = payload.customize_func ? payload.customize_func : [] // 取api原本的內容
    state.frPhotoRegisterCount =
      payload.frPhotoRegisterCount === undefined
        ? 0
        : payload.frPhotoRegisterCount
    state.map_type = payload.map_type ? payload.map_type : 0

    // 若 localStorage 有值，則取 localStorage 值，否則取系統預設值 map_type: 0(預設) 或 1(衛星)
    const setMapType = state.map_type === 1 ? 'hybrid' : 'terrain'
    const vuexObj = localStorage.getItem('vuex')
      ? JSON.parse(localStorage.getItem('vuex'))
      : {}
    state.mapType = vuexObj.mapType || setMapType

    // 若 localStorage 有值，則取 localStorage 值，否則取 customize_func 判斷是否為 'park'
    if (vuexObj && vuexObj.videoViewMode) {
      state.videoViewMode = vuexObj.videoViewMode
    } else {
      // 停車場模式的多宮模式預設值為 2x2
      state.videoViewMode = getters.isCustomizeFuncPark(state)
        ? euVideoDisplayMode.mode_2x2
        : euVideoDisplayMode.mode_1x1
    }
    // console.log(`[Store.updateWebPreferences] state.videoViewMode`, state.videoViewMode)

    state.isGetWebPreferences = true // 一定要放最後，確保前面的值都已經取完，再更新狀態
  },
  updateIsParking(state, payload) {
    // console.log(`[Store.mutation.updateIsParking] payload`, payload)
    state.isParking = payload
  },
  updateShowDownloadPanel(state, payload) {
    state.showDownloadPanel = payload
  },
  updateWebAnnouncements(state, payload) {
    state.webAnnouncements = payload
  },
  updateIsPageVisible(state, payload) {
    state.isPageVisible = payload
  },
  updateRightClickUser(state, payload) {
    state.rightClickUser = payload
  },
  updateShowAccountInfoModal(state, payload) {
    state.showAccountInfoModal = payload
  },
  updateEventDisplaySetting(state, payload) {
    state.eventDisplaySetting = payload
  },
  updateLatestEventId(state, { type, id }) {
    state.latestEventId[type] = id
  },
  updateMapType(state, payload) {
    state.mapType = payload
  },
}

const actions = {
  resetState({ commit }) {
    const initial = initialState()
    commit('login', false)
    commit('updatePermissionV2', initial.permissionV2)
    commit('updateSsoLogin', initial.ssoLogin)
    commit('updateStaff', initial.staff)
    commit('updateUserFlatObj', initial.userFlatObj)
    commit('updateDeviceTabIdx', initial.deviceTabIdx)
    commit('updateShowCheckedAll', initial.showCheckedAll)
    commit('updateShowCheckedOnline', initial.showCheckedOnline)
    commit('updateShowCheckedLive', initial.showCheckedLive)
    commit('updateShowFilter', initial.showFilter)
    commit('updateAutoSelectAllDevices', initial.autoSelectAllDevices)
    commit('updateWatchUsers', initial.watchUsers)
    commit('setSelectedUsers', initial.selectedUsers)
    commit('updateLeftMenuOpen', initial.LeftMenuOpen)
    commit('updateRightMenuOpen', initial.RightMenuOpen)
    commit('updateNotAutomaticLogout', initial.notAutomaticLogout)
    commit('updateShowMapOnlineDevices', initial.showMapOnlineDevices)
    commit('updateShowFullTitle', initial.showFullTitle)
    commit('updateShowAccountInTitle', initial.showAccountInTitle)
    commit('updateShowLiveRecognitionFrame', initial.showLiveRecognitionFrame)
    commit('updateVideoFill', initial.videoFill)
    commit('updateShowAccountInDeviceName', initial.showAccountInDeviceName)
    commit('updateLiveEventOn', { sts: false, url: '', origin: '' })
    commit('updateTopMenuHeightLevel', 1) // 關閉Video視窗
    commit('updateVideoViewMode', initial.videoViewMode) // 設定多宮格模式
    commit('setUserList', initial.userList) // 切換不同帳號燈入時, 需要清空
    commit('updateEventDisplaySetting', initial.eventDisplaySetting)
  },
  async logout({ commit }) {
    try {
      await apiLogout()
      commit('login', false)
      commit('setAccessToken', '')
      localStorage.setItem('refreshToken', '')
      commit('updatePermissionV2', initialState().permissionV2)
      commit('updateSsoLogin', initialState().ssoLogin)
      commit('updateStaff', initialState().staff)
      commit('updateLiveEventOn', { sts: false, url: '', origin: '' })
      commit('updateTopMenuHeightLevel', 1) // 關閉Video視窗
    } catch (err) {
      console.log(err)
    }
  },
  async refreshToken({ commit }) {
    try {
      const data = { refreshToken: crypto.decrypt(localStorage.getItem('refreshToken')) }
      const res = await axios.post('/api/web/refresh', data)
              
      commit('setAccessToken', res.data.accessToken)
      commit('updatePermissionV2', res.data.user.permissionV2)
      commit('updateStaff', res.data.user.staff)
      localStorage.setItem('refreshToken', crypto.encrypt(res.data.refreshToken))
    } catch(error) {
      // [更新 access_token 失敗] ( e.g. refresh_token 過期無效
      commit('login', false)
      commit('setAccessToken', '')
      localStorage.setItem('refreshToken', '')
      console.log('更新token失敗: ', error.message )
      if (router.currentRoute.path !== '/login') router.push('/login')
    }
  },
  async getWebPreferences({ commit }) {
    try {
      const res = await apiGetWebPreferences()
      commit('updateWebPreferences', res.data.setting)
    } catch (err) {
      console.log(err)
    }
  },
  async getWebAnnouncements({ commit }) {
    try {
      const res = await apiGetWebAnnouncements()
      commit('updateWebAnnouncements', res.data)
    } catch (err) {
      console.log(err)
    }
    
  },
  async getUser({ commit, state }) {
    try {
      const res = await apiGetUser()
      commit('updateUserFlatObj', res.data)
      // 設定語系:個人設定 ===> 系統設定 ===> 瀏覽器語系
      if (
        res.data.language &&
        supportLangs.map(({ key }) => key).includes(res.data.language)
      ) {
        i18n.locale = res.data.language
      } else if (state.systemLanguage) {
        i18n.locale = state.systemLanguage
      } else {
        // const navLang = navigator.language
        // let lang = 'en'
        // if (navLang === 'zh-TW') lang = 'zh'
        // else if (navLang === 'en-US' || navLang === 'en') lang = 'en'
        const lang = getLang(navigator.language)
        i18n.locale = lang
      }
      // for flutter used
      // localStorage.setItem('flutter.locale', `"${i18n.locale}"`)

      // 設定時區：個人設定 ===> 系統設定 ===> 本機時區
      // 時區設定由getters timezone 處理
    } catch (err) {
      console.log(err)
    }
  },
  async getUserList({ commit }) {
    const res = await apiGetUserList({ kind: 'device', enabled: 1, public: null })
    commit('setUserList', res.data)
  },
  async getUserFullList({ commit }) {
    const res = await apiGetUserList({ kind: 'device', enabled: null, public: null })
    commit('setUserFullList', res.data)
  },
  async getGroupList({ commit }) {
    const res = await apiGetGroupList()
    commit('updateGroupList', res.data)
  },
  async getSettingGeneral({ commit }) {
    try {
      let res = await apiGetSettingGeneral()
      if (res.status == 200) {
        commit('updateSystemLanguageTimezone', {
          language: res.data.language,
          timezone: res.data.timezone
        })
      }
    } catch (err) {
      console.log('getGlobalSetting error: ', err)
    }
  },
  handleEventModal({ commit, state }, eventObj) {
    // 點擊卡片要更新eventDetailObj，如果是追車事件，要再多更新liveVideoUrl
    // 如果新舊卡片跳窗型態不同，再去更新不同跳窗的開關狀態
    // 從比對or緊急切換成追車時 如果同時有left bar點出的影片在播放 就在不更動top bar比例的情況下更新影片
    // let sameTyp
    // if (Object.keys(state.eventCardDetailObj).length) {
    //   sameTyp =
    //     state.eventCardDetailObj.chasing == eventObj.chasing ? true : false
    // } else {
    //   sameTyp = false
    // }

    commit('updateEventCardDetailObj', eventObj)
    const eventUserId = eventObj.uid.startsWith('sos') ? 
      eventObj.userAccount : eventObj.user.id

    const isSosing = eventObj.uid.startsWith('sos') && !eventObj.endTime
    
    let url
    if (eventObj.chasing == 1 || isSosing) {
      url = state.liveList.find((item) => item.id == eventUserId)?.mseUrl
    }
      
    if (eventObj.chasing == 1 || isSosing) {
      commit('updateShowEventCardDetail', false)
      commit('updateTopMenuHeightLevel', 2)
      commit('updateLiveEventOn', { sts: true, url: url, origin: 'rightBar' })
      
      if (state.matchLiveOrigin != 'leftBar') {
        commit('updateTopMenuHeightLevel', 2)
      }
    } else {
      commit('updateShowEventCardDetail', true)

      // 以下程式碼是打開了大卡片之後，把影片播放視窗關掉
      // commit('updateLiveEventOn', { sts: false, url: '', origin: '' })
      // commit('updateTopMenuHeightLevel', 1)
    }
  },
  switchLeftAndRightLiveEvent({ commit, state }, { id, url, typ }) {
    // 點擊left bar 有icon的帳號
    // 如果沒有正在直播的影片 就要把top bar打開
    // 如果有正在直播的影片 而且是從right bar點開的 就要取消right bar eventcard的選中底色

    // 跳窗可能開著 要關掉
    commit('updateShowEventCardDetail', false)

    if (!state.matchLiveOrigin) {
      commit('updateTopMenuHeightLevel', 2)
    }
    if (state.matchLiveOrigin == 'rightBar') {
      commit('updateEventCardDetailObj', {})
    }

    let event = state.lprEventList.find((evt) => evt.user.id == id)
    commit('updateEventCardDetailObj', event ? event : {})

    // 如果點中的是left bar的追車事件 就要選中right bar的卡片 這裡還沒處理完 因為不確定左邊資料要如何對應到右邊的資料
    if (typ == 'live') {
      commit('updateLiveEventOn', { sts: true, url: url, origin: 'leftBar' })
      commit('updateTopMenuHeightLevel', 3) // Live開啟直播需要直接開2/3模式
    } else { // chasing, sos
      commit('updateLiveEventOn', { sts: true, url: url, origin: 'rightBar' })
      commit('updateTopMenuHeightLevel', 2) // 追車/SOS事件開1/3模式
    }
  },
  /** Get tag list */
  async getTagList({ commit }) {
    try {
      const res = await apiGetTagList()
      commit('updateTagList', res.data?.tagList ? res.data.tagList : [])
    } catch (err) {
      console.log(err)
    }
  },
  async getFrTagList({ commit }) {
    try {
      const res = await apiGetFrTagList()
      commit('updateFrTagList', res.data?.tagList ? res.data.tagList : [])
    } catch (err) {
      console.log(err)
    }
  },
  async getBoviaLprCodebooks({ commit }) {
    try {
      let res = await apiGetBoviaLprCodebooks()
      commit('updateCodeBooks', res.data)
      res = null
    } catch (err) {
      console.log(err)
    }
  },
  resetVideoGpsMarkerPath({ commit }) {
    commit('updateVideoGpsList', [])
    commit('updateVideoMarker', {})
    commit('clearVideoPath')
  },
  /** Get gpsList of video */
  async getVideoGpsList({ commit }, payload) {
    try {
      let res = await apiGetVideoGPS(payload.videoId)
      if (res.data.gpsList) {  // 沒有資料會回傳null
        commit('addVideoGpsList', res.data.gpsList) 
      }
      res = null
    } catch (err) {
      console.log('getVideoGPS error:', err)
    }
  },
  /** Get gpsList, Marker of video */
  async getVideoGpsMarker({ commit }, payload) {
    try {
      let gpsList = []
      let res = await apiGetVideoGPS(payload.videoId)
      if (res.data.gpsList) {  // 沒有資料會回傳null
        gpsList = [...res.data.gpsList] 
      }

      commit('clearVideoPath')
      commit('updateVideoGpsList', gpsList)
      commit('updateVideoMarker', {
        id: payload.userId,
        name: payload.userName,
        videoTitle: payload.videoTitle,
        position: {
          lat: gpsList.length > 0 ? gpsList[0].latitude : 0.0,
          lng: gpsList.length > 0 ? gpsList[0].longitude : 0.0,
          timestamp: gpsList.length > 0 ? gpsList[0].timestamp : ''
        }
      })
      res = null
    } catch (err) {
      console.log('getVideoGPS error:', err)
    }
  },
  checkAndUpdateSosEvents({ state, dispatch }) {
    const sosingIds = state.sosEventList.filter(event => !event.endTime).map(event => event.id)
    if (state.sosConnectionList.length === 0 && sosingIds.length === 0) return
    
    dispatch('getSosEventList')
  },
  async getSosEventList({ commit, state }) {
    // 取今天 00:00:00~ 的SOS事件
    let today = new Date()
    const params = {
      afterStartTime: new Date(today.setHours(0, 0, 0, 0)).toISOString()
    }

    const res = await apiGetSosEventsDashboard(params)
    const sosEvents = []
    res.data.forEach(event => {
      event.uid = `sos-${event.id}`
      event.timestamp = new Date(event.startTime).getTime()
      sosEvents.push(event)
    })
    commit('updateSosEventList', sosEvents)
  },
  async getV4ServerSetting({ commit }, data) {
    try {
      const res = await apiGetV4ServerSetting()

      if (!apiCheckStatus(res)) throw res

      const {
        isParking,
        // allowParkingUnit
      } = res.data

      commit('updateIsParking', isParking)
    } catch (err) {
      if (err) {
        const msg = apiErrorMsg(err)
        console.error(`[getV4ServerSetting]`, msg)
      } else {
        console.error(`[getV4ServerSetting]`, err)
      }
      return
    }
  }
}
const getters = {
  getOnlineStatus: (state) => (userId) => {
    // 2022.05.14 若裝置有在connectionList或是在liveList中，表示online
    const connIds = new Set(state.connectionList.map(conn => conn.userAccount))
    const liveIds = new Set(state.liveList.map(live => live.id))
    return connIds.has(userId) || liveIds.has(userId)
  },
  getLiveStatus: (state) => (userId) => {
    const liveIds = new Set(state.liveList.map(live => live.id))
    return liveIds.has(userId)
  },
  getSosStatus: (state) => (userId) => {
    // connections API 中 SOS 欄位只要 > 0, 就表示有 SOS 事件 (>0 的數字是 SOS 的事件 id)
    return state.connectionList.some(conn => conn.sos > 0 && conn.userAccount === userId)
  },
  getChasingStatus: (state) => (userId) => {
    return state.connectionList.some(conn => conn.lprChasing > 0 && conn.userAccount === userId)
  },
  chasingEvent(state) {
    return state.lprEventList.filter((item) => item.chasing === 1)
  },
  displayLprEvents(state) {
    // 根據勾選的設備，或是圍捕事件(event.chasing=1)，從lprEventList中取得最新的200筆事件，顯示於事件列表
    // 2024.10.17 新增根據eventDisplaySetting.lpr 過濾事件
    const selectedUserIds = new Set(state.selectedUsers.map(user => user.id))
    const { display: matchDisplay, tags: matchTags } = state.eventDisplaySetting.lpr.match
    const { display: noMatchDisplay } = state.eventDisplaySetting.lpr.noMatch
    let lprEvents = []
    if (matchTags.length > 0 && matchDisplay) {
      lprEvents = state.lprEventList.filter(event => {
        if (event.chasing === 1) return true
        if (selectedUserIds.has(event.user.id)) {
          // 比對符合的事件才需要針對tag進行過濾
          if (event.matched === 1) {
            return event.triggered[0].tag.some(tag => matchTags.includes(tag))
          } else {
            return noMatchDisplay // 比對不符合的事件是否顯示
          }
        }
      })
    } else {
      lprEvents = state.lprEventList.filter(event => 
        selectedUserIds.has(event.user.id) || event.chasing === 1
      )
    }

    return lprEvents.slice(0, 200)
  },
  displayFrEvents(state) {
    // 根據勾選的設備，從frEventList中取得最新的200筆事件，顯示於事件列表
    const selectedUserIds = new Set(state.selectedUsers.map(user => user.id))
    const { display: matchDisplay, tags: matchTags } = state.eventDisplaySetting.fr.match
    const { display: noMatchDisplay } = state.eventDisplaySetting.fr.noMatch
    let frEvents = []
    if (matchTags.length > 0 && matchDisplay) {
      frEvents = state.frEventList.filter(event => {
        if (selectedUserIds.has(event.user.id)) {
          // 比對符合的事件才需要針對tag進行過濾
          if (event.matched === 1) {
            return event.triggered[0].tag.some(tag => matchTags.includes(tag))
          } else if (event.matched === 2) {
            return true 
          } else {
            return noMatchDisplay // 比對不符合的事件是否顯示
          }
        }
      })
    } else {
      frEvents = state.frEventList.filter(event => 
        selectedUserIds.has(event.user.id)
      )
    }

    return frEvents.slice(0, 200)
  },
  displayOrEvents(state) {
    // 根據勾選的設備，從frEventList中取得最新的200筆事件，顯示於事件列表
    const selectedUserIds = new Set(state.selectedUsers.map(user => user.id))
    const { display, category } = state.eventDisplaySetting.or
    let orEvents = []
    if (category.length > 0 && display) {
      // 根據category過濾事件
      orEvents = state.orEventList.filter(event => {
        if (selectedUserIds.has(event.user.id)) {
          if (event.motion === 1 && category.includes(6)) return true
          return event.objects.some(obj => category.includes(obj.class))
        }
      })
    } else {
      orEvents = state.orEventList.filter(event => 
        selectedUserIds.has(event.user.id)
      )
    }

    return orEvents.slice(0, 200)
  },
  displayParkingEvents(state) {
    // TODO
    const selectedUserIds = new Set(state.selectedUsers.map(user => user.id))
    // const { general, vip, blacklist, unknown } = state.eventDisplaySetting.parking

    let pEvents = state.parkingEventList.filter(event =>
      selectedUserIds.has(event.user.id)
    )

    return pEvents.slice(0, 200)
  },
  displayEventList(state, getters) {
    // 合併displayLprEvents與displayFrEvents, displayOrEvents 並依照時間排序，取最新的200筆
    const displayEventList = [
      ...state.sosEventList, 
      ...getters.displayLprEvents, 
      ...getters.displayFrEvents, 
      ...getters.displayOrEvents,
      ...getters.displayParkingEvents
    ]
      .sort((a, b) => b.timestamp - a.timestamp)
      .slice(0, 200)
    return displayEventList
  },
  getUserInfo(state) {
    let tempObj
    if (state.userFlatObj) {
      tempObj = state.userFlatObj
    } else {
      tempObj = {
        id: '',
        index: '',
        name: '',
        groupId: '',
        groupName: '',
        roleId: '',
        roleName: '',
        email: '',
        phone: '',
        language: '',
        timezone: '',
        lastLogin: '',
        '2faEnabled': 0,
        '2faMode': 0,
        kind: 0, // 預設
      }
    }
    return tempObj
  },
  timezone(state) {
    // 其他元件透過getters timezone取得時區，為了確保不會因為時區轉換出錯，
    // 若取得的時區為undefined時，則一率返回本機的時區
    // 設定時區：個人設定 ===> 系統設定 ===> 本機時區
    if (state.userFlatObj.timezone) return state.userFlatObj.timezone
    else if (state.systemTimezone) return state.systemTimezone
    else return moment.tz.guess()
  },
  isTransparent(state) {
    if (state.RightMenuOpen) {
      return false
    }
    if (state.TopMenuHeightLevel > 1) {
      return false
    }
    if (state.isHoverTop || state.isHoverRight) {
      return false
    }
    return true
  },
  trackUserPath(state) {
    if (!state.trackUser.id) return []

    let idx = state.markerGpsList.findIndex(
      (item) => item.userAccount == state.trackUser.id
    )
    return idx == -1 ? [] : state.markerGpsList[idx].gpsData
  },
  gpsMarkers(state) {
    const showDevices = state.showMapOnlineDevices ? 
    state.selectedUsers.filter((user) => { 
      return state.connectionList.find(item => item.userAccount === user.id) ||
        state.liveList.find(item => item.id === user.id) 
    }) : state.selectedUsers
      
    let markers = []
    showDevices.forEach((user) => {
      let index = state.markerGpsList.findIndex(
        (item) => item.userAccount == user.id
      )
      if (index >= 0) {
        let gpsData = state.markerGpsList[index].gpsData
        let lastGps = gpsData[gpsData.length - 1]
        markers.push({
          id: user.id,
          name: user.name,
          deviceType: state.markerGpsList[index].deviceType,
          position: {
            lat: lastGps.lat,
            lng: lastGps.lng,
            timestamp: lastGps.timestamp
          }
        })
      } else {
        /**
         * 離線設備(沒有GPS資料)，從userList中取得設備曾經上線的GPS資料
         */
        let userIndex = state.userList.findIndex(
          (item) => item.id === user.id
        )
        if (userIndex >= 0) {
          const lastGps = state.userList[userIndex].gps
          // 如果設備有發過 GPS, 就會有 “gps“ 欄位; 若沒發過就沒有 “gps“ 資訊 gps=undefined
          // 2023.09.09 
          // gps.lock=1 要顯示在地圖上
          // gps.lock=0 timestamp有值 要顯示在地圖上
          // gps.lock=0 沒有timestamp 不顯示在地圖上
          if (lastGps && (lastGps.lock === 1 || (lastGps.lock === 0 && lastGps.timestamp))) {
            markers.push({
              id: user.id,
              name: user.name,
              deviceType: '',
              position: {
                lat: lastGps.latitude,
                lng: lastGps.longitude,
                lock: lastGps.lock,
                old: lastGps.old,
                speed: lastGps.speed,
                timestamp: lastGps.timestamp,
              }
            })
          }
        }
      }
    })
    return markers
  },
  rightClickUser(state) {
    return state.userList.find(user => user.id === state.rightClickUser.id)
  },
  // 停車場系統
  isCustomizeFuncPark(state) {
    // console.log(`[Store.getter.isCustomizeFuncPark] isParking`, state.isParking)

    return state.isParking
  },
  // 地圖開關
  isTurnOnMap(state) {
    // console.log(`[isTurnOnMap]map_type:`, state.map_type)
    const ret = state.map_type !== euMapType.disable

    return ret
  },
}

const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    account,
    global,
    deviceCtrl,
    history,
    userinfo,
    aibox,
    recgFr,
    recgLpr,
    recgOr,
    setting, // System & Service Setting
    video,
    frDb
  },
  plugins: [
    createPersistedState({
      key: 'vuex',
      reducer(state) {
        return {
          isLogin: state.isLogin,
          staff: state.staff,
          userFlatObj: state.userFlatObj,
          deviceTabIdx: state.deviceTabIdx,
          showCheckedAll: state.showCheckedAll,
          showCheckedOnline: state.showCheckedOnline,
          showCheckedLive: state.showCheckedLive,
          showFilter: state.showFilter,
          autoSelectAllDevices: state.autoSelectAllDevices,
          watchUsers: state.watchUsers,
          selectedUsers: state.selectedUsers, // L2 勾選帳號
          videoViewMode: state.videoViewMode, // 多宮格模式
          LeftMenuOpen: state.LeftMenuOpen,
          RightMenuOpen: state.RightMenuOpen,
          permissionV2: state.permissionV2,
          notAutomaticLogout: state.notAutomaticLogout,
          showMapOnlineDevices: state.showMapOnlineDevices,
          showFullTitle: state.showFullTitle,
          showAccountInTitle: state.showAccountInTitle,
          showLiveRecognitionFrame: state.showLiveRecognitionFrame,
          videoFill: state.videoFill,
          showAccountInDeviceName: state.showAccountInDeviceName,
          systemLanguage: state.systemLanguage,
          systemTimezone: state.systemTimezone,
          systemName: state.systemName,
          webAnnouncements: state.webAnnouncements,
          banner: state.banner,
          eventDisplaySetting: state.eventDisplaySetting,
          mapType: state.mapType,
        }
      }
    }),
    createPersistedState({
      key: 'general',
      reducer(state) {
        return {
          // this isn't used in `subscription2`.
          // TODO We should remove it when `subscription` has been removed from this project.
          locale: state.userFlatObj.language,
          baseURL: base_apiurl
        }
      }
    })
  ]
})

export default store

import { ptzDeviceModelId } from '@/config/account.js'

const initialState = () => ({
  showDeviceCtrl: false,
  deviceCtrlMode: '', // ptz, image, device
  ptzData: {},
  codecs: '',
  framerate: '',
  resolution: '',
  bitrate: '',
  audioBitrate: '',
  protocol: '',
  dataRxBitrate: { average: '', history: '' },
  videoSrcBitrate: { average: '', history: '' },
  videoSrcFramerate: { average: '', history: '' },
  videoInBitrate: { average: '', history: '' },
  videoInFramerate: { average: '', history: '' },
  videoRxBitrate: { average: '', history: '' },
  videoRxFramerate: { average: '', history: '' },
  videoRxSrcCorrelHistory: '',
  videoRxInCorrelHistory: '',
})

const state = initialState()

const mutations = {
  resetState(state) {
    const initial = initialState()
    Object.keys(initial).forEach(key => { state[key] = initial[key] })
  },
  updateShowDeviceCtrl(state, payload) {
    state.showDeviceCtrl = payload
  },
  updateDeviceCtrlMode(state, payload) {
    state.deviceCtrlMode = payload
  },
  updatePtzData(state, payload) {
    state.ptzData = payload
  },
  updateCodecs(state, payload) {
    state.codecs = payload
  },
  updateFramerate(state, payload) {
    state.framerate = payload
  },
  updateResolution(state, payload) {
    state.resolution = payload
  },
  updateBitrate(state, payload) {
    state.bitrate = payload
  },
  updateAudioBitrate(state, payload) {
    state.audioBitrate = payload
  },
  updateConfigured(state, payload) {
    state.configured = payload
  },
  updateDataRxBitrate(state, {average, history}) {
    state.dataRxBitrate.average = average
    state.dataRxBitrate.history = history
  },
  updateVideoSrcBitrate(state, {average, history}) {
    state.videoSrcBitrate.average = average
    state.videoSrcBitrate.history = history
  },
  updateVideoSrcFramerate(state, {average, history}) {
    state.videoSrcFramerate.average = average
    state.videoSrcFramerate.history = history
  },
  updateVideoInBitrate(state, {average, history}) {
    state.videoInBitrate.average = average
    state.videoInBitrate.history = history
  },
  updateVideoInFramerate(state, {average, history}) {
    state.videoInFramerate.average = average
    state.videoInFramerate.history = history
  },
  updateVideoRxBitrate(state, {average, history}) {
    state.videoRxBitrate.average = average
    state.videoRxBitrate.history = history
  },
  updateVideoRxFramerate(state, {average, history}) {
    state.videoRxFramerate.average = average
    state.videoRxFramerate.history = history
  },
  updateVideoRxSrcCorrelHistory(state, payload) {
    state.videoRxSrcCorrelHistory = payload
  },
  updateVideoRxInCorrelHistory(state, payload) {
    state.videoRxInCorrelHistory = payload
  },
}

const actions = {}

const getters = {
  isSupportPtz: (state, getters, rootState) => (deviceId) => {
    if (!deviceId) return false
    // 名稱為R3開頭或內有PTZ的即為PTZ設備
    const live = rootState.liveList?.find((item) => item.id == deviceId)
    // 用設備類型(ptzDeviceModelId = [10, 20, 100, 110, 2000, 2010])判斷
    const user = rootState.userList.find((item) => item.id == deviceId)
    const isPtzDevice = ptzDeviceModelId.includes(user?.deviceModelId)
    const type = live ? live.device.info.type : ''
    const regex = /^R3/
    return regex.test(type) || type.includes('PTZ') || isPtzDevice
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
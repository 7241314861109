import lpr from './lpr'
import fr from './fr'
import or from './or'
import parking from './parking'
import urgent from './urgent'
import video from './video'

const initialState = () => ({
  eventType: 'lpr', // 歷史事件類型：lpr, fr, or, urgent, video, parking
  eventModalShow: false, // 歷史事件大卡片彈窗顯示與否
  isShowZoomPhoto: false, // 在大卡片是否顯示放大照片
})

const state = initialState()

const getters = {
}

const mutations = {
  resetState(state) {
    const initial = initialState()
    Object.keys(initial).forEach(key => { state[key] = initial[key] })
  },
  updateEventType(state, value) {
    state.eventType = value
  },
  updateEventModalShow(state, value) {
    state.eventModalShow = value
  },
  updateIsShowZoomPhoto(state, value) {
    state.isShowZoomPhoto = value
  },
}

const actions = {
}

export default {
  namespaced: true,
  modules: {
    lpr,
    fr,
    or,
    parking,
    urgent,
    video
  },
  state,
  getters,
  mutations,
  actions
}
import Vue from 'vue'
import { apiGetTagList } from "@/api/index"
import {
  apiGetAiBoxes,
  apiGetAiBoxTasks,
  apiPostAiBoxTask,
  apiPutAiBoxTask,
  apiDeleteAiBoxTask,
  apiPostAiBoxTaskSubscriber,
  apiDeleteAiBoxTaskSubscriber,
  apiGetAiboxTaskWebhooks,
  apiEditAiboxTaskWebhook
} from '@/api/v4'
import i18n from "@/i18n/i18n"
import { initAiboxTaskOr, initAiboxTaskLpr } from '@/config/configAibox'
import { getDeepKeys } from '@/utils/lib'
import { patrolDeviceModelId, euDeviceModelId } from '@/config/account.js'

const initialState = () => ({
  aiMode: '', // or, fr, lpr
  aiRunOn: null, // device, aibox
  aiSettingMode: 'recognition', // recognition, notify; 設定辨識參數 or 設定通知參數
  isEdit: false, // 是否為編輯模式
  isEditRoi: false, // 是否為編輯ROI模式
  showAiBox: false,  
  showAiboxPortal: false,
  paramAiboxPortal: {}, // info: cancelEdit / deleteTask / resourceNotAvailable, action: closeModal / switchTask(taskId) / frScoreAdjust
  taskId: -1,
  aiBoxes: [],
  aiBoxTasks: [],
  origAiBoxTask: null, // 進入編輯模式時的原始aiBoxTask
  editRoi: false,
  polygonMode: false,
  setAreaNo: '-1', // 1, 2, 3
  isResetRoi: false,
  
  // LPR
  tagList: [],

  // DeviceCtrl: include ptz
  showDeviceCtrl: false,

  // 直播辨識框
  showRecgBox: false,

  // 通報設定
  isNotifyEdit: false,

  // LINE notify webhook
  setSubscriberMode: 'BoviLive', // 設定接收人模式 BoviLive, LINE
  activeLineWebhookId: null, // active LINE webhook id
  isWebhookModified: false, // webhook 是否有修改

  missingTags: [], // 用來記錄缺少的參數
})

const state = initialState()

const mutations = {
  resetState(state) {
    Object.assign(state, initialState())
  },
  updateAiMode(state, data) {
    state.aiMode = data // or, fr, lpr
  },
  updateAiRunOn(state, data) {
    state.aiRunOn = data // device, aibox
  },
  updateAiSettingMode(state, data) {
    state.aiSettingMode = data // recognition, notify
  },
  updateIsEdit(state, boolValue) {
    state.isEdit = boolValue
  },
  updateIsEditRoi(state, boolValue) {
    state.isEditRoi = boolValue
  },
  updateShowAiBox(state, boolValue) {
    state.showAiBox = boolValue
    if (boolValue === false) state.taskId = -1
  },
  updateShowAiboxPortal(state, payload) {
    state.showAiboxPortal = payload
  },
  updateParamAiboxPortal(state, payload) {
    state.paramAiboxPortal = payload
  },
  updateTaskId(state, id) {
    state.taskId = id
  },
  updateAiBoxes(state, list) {
    state.aiBoxes = list
  },
  unshiftAiBoxTask(state, task) {
    state.aiBoxTasks.unshift(task)
  },
  updateAiBoxTasks(state, list) {
    // 根據createdTime進行排序，較新的排在上面
    list.sort((a, b) => {
      const timeA = new Date(a.createdTime).getTime()
      const timeB = new Date(b.createdTime).getTime()
      return (timeA > timeB) ? -1 : (timeA < timeB) ? 1 : 0 
    })
    state.aiBoxTasks = list
  },
  updateTaskAiBox(state, payload) {
    let index = state.aiBoxTasks.findIndex(item => item.id === state.taskId)
    if (index >= 0) {
      state.aiBoxTasks[index].aiboxId = payload.id
      state.aiBoxTasks[index].aiboxName = payload.name
    }
  },
  updateCurrTaskConfig(state, payload) {
    let index = state.aiBoxTasks.findIndex(item => item.id === state.taskId)
    if (index >= 0) {
      state.aiBoxTasks[index].config = payload
    } 
  },
  updateEditRoi(state, payload) {
    state.editRoi = payload
  },
  updatePolygonMode(state, value) {
    state.polygonMode = value
  },
  updateSetAreaNo(state, data) {
    state.setAreaNo = data
  },
  updateIsResetRoi(state, value) {
    state.isResetRoi = value
  },
  updateNotifyFilterMode(state, data) {
    const task = state.aiBoxTasks.find(task => task.id === state.taskId)
    if (!task) return
    task.notifyFilterMode = data
  },
  updateNotice(state, data) {
    const task = state.aiBoxTasks.find(task => task.id === state.taskId)
    if (!task) return
    task.notice = data
  },
  updateSubscribers(state, data) {
    const task = state.aiBoxTasks.find(task => task.id === state.taskId)
    if (!task) return
    task.subscribers = data
  },
  updateOrigAiBoxTask(state, data) {
    state.origAiBoxTask = JSON.parse(JSON.stringify(data))
  },
  updateAiModelType(state, data) {
    const task = state.aiBoxTasks.find(task => task.id === state.taskId)
    if (!task) return
    task.config.aiModelType = data
  },
  updateConfig(state, payload) {
    const task = state.aiBoxTasks.find(task => task.id === state.taskId)
    if (!task) return
    task.config[payload.modelType] = payload.data
  },
  updateTagList(state, data) {
    state.tagList = data
  },
  updateTagFilter(state, data) {
    const task = state.aiBoxTasks.find(task => task.id === state.taskId)
    if (!task) return
    let useModelType = task.config.aiModelType // tw, jp, vn
    useModelType = 'lpr' + useModelType.charAt(0).toUpperCase() + useModelType.slice(1) // lprTw, lprJp, lprVn
    task.config[useModelType].tagFilter = data
  },
  updateROI(state, data) {
    const task = state.aiBoxTasks.find(task => task.id === state.taskId)
    if (!task) return
    const aiModelType = task.config.aiModelType // tw, jp, vn
    const lprModelType = 'lpr' + aiModelType.slice(0, 1).toUpperCase() + aiModelType.slice(1)
    task.config[lprModelType].roi = data
  },
  updateLprSizeRatio(state, data) {
    const task = state.aiBoxTasks.find(task => task.id === state.taskId)
    if (!task) return
    const aiModelType = task.config.aiModelType // tw, jp, vn
    const lprModelType = 'lpr' + aiModelType.slice(0, 1).toUpperCase() + aiModelType.slice(1)
    task.config[lprModelType].lprSizeRatio = data
  }, 
  updateShowDeviceCtrl(state, value) {
    state.showDeviceCtrl = value
  },
  updateShowRecgBox(state, value) { 
    state.showRecgBox = value
  },
  updateIsNotifyEdit(state, value) {
    state.isNotifyEdit = value
  },
  updateSetSubscriberMode(state, data) {
    state.setSubscriberMode = data
  },
  updateActiveLineWebhookId(state, data) {
    state.activeLineWebhookId = data
  },
  updateIsWebhookModified(state, value) {
    state.isWebhookModified = value
  },
  updateMissingTags(state, data) {
    state.missingTags = data
  },
}

const actions = {
  initRoiSetting({ commit }) {
    commit('updatePolygonMode', false)
    commit('updateSetAreaNo', -1)
  },
  async getTagList({commit}) {
    try {
      const res = await apiGetTagList()
      commit('updateTagList', res.data.tagList)
    } catch (err) {
      console.log('發生錯誤：', err)
    }
  },
  async getAiBoxes({ commit }) {
    try {
      const res = await apiGetAiBoxes()
      commit('updateAiBoxes', res.data)
    } catch (err) {
      console.log(err)
    }
  },
  async getAiBoxTasks({ commit, getters }) {
    try {
      const res = await apiGetAiBoxTasks(String(getters.deviceIndex)) // deviceIndex為整數，api參數型別需string
      commit('updateAiBoxTasks', res.data)
    } catch (err) {
      console.log(err)
    }
  },
  async initialAiBoxTask({ state, commit, getters, dispatch }, payload) {
    await dispatch('getAiBoxes')
    let newTask = JSON.parse(JSON.stringify(payload))
    
    // 設定辨識盒, 依照規則排序：Capability - UsedResouces - Math.floor(timeSinceLastUpdated / 10)
    // 取用分數最高的辨識盒
    const canUseAiBoxes = state.aiBoxes.filter(item => (item[`${payload.ai}Capability`] - item[`${payload.ai}UsedResouces`]) > 0)
    canUseAiBoxes.sort((a, b) => {
      const scoreA = a[`${payload.ai}Capability`] - a[`${payload.ai}UsedResouces`] - Math.floor(a.timeSinceLastUpdated / 10)
      const scoreB = b[`${payload.ai}Capability`] - b[`${payload.ai}UsedResouces`] - Math.floor(b.timeSinceLastUpdated / 10)
      return scoreB - scoreA
    })

    if (canUseAiBoxes.length > 0) {
      newTask.aiboxId = canUseAiBoxes[0].id
      newTask.aiboxName = canUseAiBoxes[0].name
    }
    
    newTask.sourceId = String(getters.deviceIndex) // 設定sourceId
    commit('unshiftAiBoxTask', newTask) // 加入第一個
    commit('updateOrigAiBoxTask', newTask) // 記錄一份最初的aiBoxTask，用於比對是否有修改
    commit('updateTaskId', -99)
    commit('updateAiMode', payload.ai)
    commit('updateAiRunOn', 'aibox')
    commit('updateIsEdit', true)
  },
  async addOrUpdateAiBoxTask({ state, dispatch }) {
    if (state.taskId === -99) {
      await dispatch('addAiBoxTask')
    } else if (state.taskId >= 0) {
      await dispatch('editAiBoxTask')
    }
  },
  async addAiBoxTask({ state, getters, commit, dispatch, rootState }) {
    if (!getters.isModified) return

    const taskData = state.aiBoxTasks.find(item => item.id === -99)
    try {
      delete taskData.id // 移除magic id
      // avoid ROI exceeding canvas 
      if (state.aiMode === 'or') {
        if (taskData.config.roi.x1 < 0) taskData.config.roi.x1 = 0
        if (taskData.config.roi.y1 < 0) taskData.config.roi.y1 = 0
        if (taskData.config.roi.x2 > 1) taskData.config.roi.x2 = 1
        if (taskData.config.roi.y2 > 1) taskData.config.roi.y2 = 1
      } else if (state.aiMode === 'lpr') {
        const lprModelType = 'lpr' + taskData.config.aiModelType.charAt(0).toUpperCase() + taskData.config.aiModelType.slice(1)
        if (taskData.config[lprModelType].roi.x1 < 0) taskData.config[lprModelType].roi.x1 = 0
        if (taskData.config[lprModelType].roi.y1 < 0) taskData.config[lprModelType].roi.y1 = 0
        if (taskData.config[lprModelType].roi.x2 > 1) taskData.config[lprModelType].roi.x2 = 1
        if (taskData.config[lprModelType].roi.y2 > 1) taskData.config[lprModelType].roi.y2 = 1
      }

      const res = await apiPostAiBoxTask(taskData)
      const newTaskId = res.data.id

      await apiPostAiBoxTaskSubscriber(newTaskId, String(rootState.userFlatObj.index)) // 新增設定人本身為接收人

      Vue.prototype.$notify({
        title: i18n.t('ai_new_recognition'),
        message: i18n.t('ai_recog_task_add_successfully'),
        type: 'success',
      })

      const userInfo = `${rootState.userFlatObj.name}(${rootState.userFlatObj.id})`

      setTimeout(() => {
        Vue.prototype.$notify({
          title: i18n.t('ai_subscriber') /*接收人*/,
          message: i18n.t('ai_subscriber_add_user', { name: userInfo }) /*已將 {name} 設定為通報接收人*/,
          type: 'success',
        })
      }, 1000)
 
      // 重新取得 AiBoxes, AiBoxTasks
      dispatch('getAiBoxes')
      const resTasks = await apiGetAiBoxTasks(String(getters.deviceIndex)) // deviceIndex為整數，api參數型別需string
      
      commit('updateTaskId', newTaskId) // commit 新增成功task的id
      commit('updateAiBoxTasks', resTasks.data)
      commit('updateOrigAiBoxTask', getters.aiBoxTask) // 更新原始aiBoxTask
    } catch (err) {
      console.log('[addAiBoxTask err]: ', err)
      // 新增後發現已經無法再加入新辨識任務, server會回傳 403 Permission Denied,
      // 顯示"無可用辨識盒"
      if (err.response.status === 403) {
        commit('updateParamAiboxPortal', { info: 'resourceNotAvailable' })
        commit('updateShowAiboxPortal', true)
      }
    }
  },
  async editAiBoxTask({ state, getters, commit, dispatch }) {
    if (!getters.isConfigModified && !getters.isNoteModified && !getters.isAiboxModified) return

    let title = ''
    let message = ''
    let type = ''

    try {
      // avoid ROI exceeding canvas 
      if (state.aiMode === 'or') {
        if (getters.aiBoxTask.config.roi.x1 < 0) getters.aiBoxTask.config.roi.x1 = 0
        if (getters.aiBoxTask.config.roi.y1 < 0) getters.aiBoxTask.config.roi.y1 = 0
        if (getters.aiBoxTask.config.roi.x2 > 1) getters.aiBoxTask.config.roi.x2 = 1
        if (getters.aiBoxTask.config.roi.y2 > 1) getters.aiBoxTask.config.roi.y2 = 1
      } else if (state.aiMode === 'lpr') {
        const lprModelType = 'lpr' + getters.aiBoxTask.config.aiModelType.charAt(0).toUpperCase() + getters.aiBoxTask.config.aiModelType.slice(1)
        if (getters.aiBoxTask.config[lprModelType].roi.x1 < 0) getters.aiBoxTask.config[lprModelType].roi.x1 = 0
        if (getters.aiBoxTask.config[lprModelType].roi.y1 < 0) getters.aiBoxTask.config[lprModelType].roi.y1 = 0
        if (getters.aiBoxTask.config[lprModelType].roi.x2 > 1) getters.aiBoxTask.config[lprModelType].roi.x2 = 1
        if (getters.aiBoxTask.config[lprModelType].roi.y2 > 1) getters.aiBoxTask.config[lprModelType].roi.y2 = 1
      }

      const data = {
        id: getters.aiBoxTask.id,
        sourceId: getters.aiBoxTask.sourceId,
        aiboxId: getters.aiBoxTask.aiboxId,
        notice: getters.aiBoxTask.notice,
        config: getters.aiBoxTask.config,
      }

      // 物件辨識沒有事件通報模式
      if (state.aiMode !== 'or') {
        data.notifyFilterMode = getters.aiBoxTask.notifyFilterMode
      }

      const res = await apiPutAiBoxTask(data)

      type = 'success'
      if (getters.isConfigModified || getters.isAiboxModified) {
        title = i18n.t('ai_edit_recognition')
        message = i18n.t('ai_recog_task_edit_successfully')
      } 
      if (getters.isNoteModified) {
        title = i18n.t('ai_notify_setting') /*通報設定*/
        message = i18n.t('ai_setting_success')
      }

      await dispatch('getAiBoxTasks')
      commit('updateMissingTags', []) // 清空missingTags
      dispatch('getAiBoxes')
      commit('updateOrigAiBoxTask', getters.aiBoxTask) // 更新原始aiBoxTask
      // dispatch('sendLineNotify') // 發送 LINE notify 通知
    } catch (err) {
      console.log(err)
      type = 'error'
      if (getters.isConfigModified || getters.isAiboxModified) {
        title = i18n.t('ai_edit_recognition')
        message = i18n.t('ai_setting_fail')
      } 
      if (getters.isNoteModified) {
        title = i18n.t('ai_notify_setting') /*通報設定*/
        message = i18n.t('ai_setting_fail')
      }
    }

    Vue.prototype.$notify({
      title,
      message,
      type,
    })
  },
  // 修改task所使用的辨識盒
  async setTaskAiBox({ getters, commit, dispatch }, aiboxId) {
    try {
      const data = {
        id: getters.aiBoxTask.id,
        aiboxId: aiboxId
      }
      const res = await apiPutAiBoxTask(data)
      await dispatch('getAiBoxTasks')
      commit('updateOrigAiBoxTask', getters.aiBoxTask) // 更新原始aiBoxTask
    } catch (err) {
      // 新增後發現已經無法再加入新辨識任務, server會回傳 403 Permission Denied,
      // 再重新取得刷新一次清單, 如果清單裡面都佔用滿了,就顯示"無可用辨識盒"
      if (err.response.status === 403) {
        await dispatch('getAiBoxes')
        commit('updateParamAiboxPortal', { info: 'resourceNotAvailable' })
        commit('updateShowAiboxPortal', true)
      }
      console.log('err.response = ', err.response)
    }
  },
  async deleteAiBoxTask({ state, commit, dispatch }) {
    const title = i18n.t('setting_aibox_task_delete_notify_title') /*刪除辨識任務*/
    let message = '', type = ''
    try {
      
      if (state.taskId >= 0) {
        await apiDeleteAiBoxTask(state.taskId)
      }
      message = i18n.t('setting_aibox_task_delete_pass') /*已成功刪除辨識任務*/
      type = 'success'
      
      // 重新取得AiBoxTasks
      dispatch('getAiBoxTasks')
      commit('updateOrigAiBoxTask', null) // 更新原始aiBoxTask
      commit('updateTaskId', -1)
      commit('updateIsEdit', false)
      commit('updateAiRunOn', null)
      commit('updateAiMode', null)

    } catch (err) {
      console.log(err)
      message = i18n.t('setting_aibox_task_delete_fail') /*刪除辨識任務失敗*/
      type = 'error'
    }

    Vue.prototype.$notify({
      title,
      message,
      type,
    })
  },
  async editSubscribers({ state, getters, commit, dispatch, rootState }) {
    const origSubscribers = state.origAiBoxTask.subscribers.map(subscriber => subscriber.userId)
    const currSubscribers = getters.aiBoxTask.subscribers.map(subscriber => subscriber.userId)
    const addIds = currSubscribers.filter(subscriber => !origSubscribers.includes(subscriber))
    
    // 要 delete 接收人前, 需先檢查該接收人是否有在自己的可視 list 中, 若無, 則不能刪除該接收人
    const minusIds = origSubscribers.filter(subscriber => !currSubscribers.includes(subscriber) && 
      rootState.account.userList.find(user => user.index === Number(subscriber)))

    if (addIds.length !== 0 || minusIds.length !== 0) {
      const promisesAdd = addIds.map(async(id) => {
        await apiPostAiBoxTaskSubscriber(state.taskId, id)
      })

      const promisesDel = minusIds.map(async(id) => {
        await apiDeleteAiBoxTaskSubscriber(state.taskId, id)
      })

      const promises = promisesAdd.concat(promisesDel)

      Promise.all(promises)
      .then(async() => {
        // 重新取得AiBoxTasks
        await dispatch('getAiBoxTasks')
        commit('updateOrigAiBoxTask', getters.aiBoxTask) // 更新原始aiBoxTask
        Vue.prototype.$notify({
          title: i18n.t('ai_subscriber') /*接收人*/,
          message: i18n.t('ai_setting_success') /*設定成功*/,
          type: 'success',
        })
      })
      .catch((error) => {
        console.log('[設定接收人失敗]=> ', error)
        // 重新取得AiBoxTasks
        dispatch('getAiBoxTasks')
        Vue.prototype.$notify({
          title: i18n.t('ai_subscriber') /*接收人*/,
          message: i18n.t('ai_setting_fail') /*設定失敗*/,
          type: 'error',
        })
      })
    }
  },
  restoreData({ state, commit, getters }) {
    if (getters.isConfigModified) {
      state.aiBoxTasks.find(task => task.id === state.taskId).config = state.origAiBoxTask.config
    }

    if (getters.isNoteModified) {
      state.aiBoxTasks.find(task => task.id === state.taskId).notice = state.origAiBoxTask.notice
      state.aiBoxTasks.find(task => task.id === state.taskId).notifyFilterMode = state.origAiBoxTask.notifyFilterMode
    }

    if (getters.isSubscribersModified) {
      state.aiBoxTasks.find(task => task.id === state.taskId).subscribers = state.origAiBoxTask.subscribers
    }

    if (getters.isAiboxModified) {
      state.aiBoxTasks.find(task => task.id === state.taskId).aiboxId = state.origAiBoxTask.aiboxId
    }

  },
  // LINE notify 2025.03.31 停止服務
  async sendLineNotify({ state, getters }) {
    // 取得 LINE notify webhooks --> 針對有啟用的 webhook 發送通知 (呼叫 edit webhook api)
    try {
      const modelMap = { lpr: 1, or: 2, fr: 3 }
      const params = { 
        taskIds: getters.aiBoxTask.id,
        models: modelMap[state.aiMode]
      }
      const res = await apiGetAiboxTaskWebhooks(params)
      const webhooks = res.data
      webhooks.forEach(webhook => {
        if (webhook.enabled === 1) {
          apiEditAiboxTaskWebhook({ id: webhook.id, taskId: webhook.taskId })
        }
      })
    } catch (err) {
      console.log(err)
    }
  },
  getMissingTags({ state, commit }) {
    const refConfig = state.aiMode === 'or' ? initAiboxTaskOr.config : initAiboxTaskLpr.config
    let config = state.aiBoxTasks.find(task => task.id === state.taskId).config
    const missingTags = fillMissingConfig(config, refConfig)
    commit('updateMissingTags', missingTags)
    commit('updateCurrTaskConfig', config)
  }
}

const getters = {
  deviceModelId(state, getters, rootState, rootGetters) {
    return rootGetters.rightClickUser.deviceModelId
  },
  deviceId(state, getters, rootState, rootGetters) {
    return rootGetters.rightClickUser.id
  },
  deviceIndex(state, getters, rootState, rootGetters) {
    return rootGetters.rightClickUser.id ? rootGetters.rightClickUser.index : ''
  },
  deviceName(state, getters, rootState, rootGetters) {
    return rootGetters.rightClickUser.id ? 
      rootGetters.rightClickUser.video.title + '('+ rootGetters.rightClickUser.id +')' : ''
  },
  canUseAiBoxes(state) {
    return state.aiBoxes.filter(item => item[`${state.aiMode}Capability`] > 0)
  },
  canShowRecgBox(state, getters) {
    // 本機辨識、patrol, bovicast_iso, bovicast_fr 設備可以顯示辨識框
    return state.aiRunOn === 'device' && 
      [...patrolDeviceModelId, 
        euDeviceModelId.BoviCast_iOS,
        euDeviceModelId.BoviCast_FR
      ].includes(getters.deviceModelId)
  },
  aiBoxTask(state) {
    return state.taskId >= 0 || state.taskId === -99 ? 
      state.aiBoxTasks.find(task => task.id === state.taskId) : null
  },
  lprModelType(state, getters) {
    const aiModelType = getters.aiBoxTask ? getters.aiBoxTask.config.aiModelType : 'tw'
    return 'lpr' + aiModelType.charAt(0).toUpperCase() + aiModelType.slice(1)
  },
  isConfigModified(state, getters) {
    if (state.missingTags.length > 0) return true
    if (getters.aiBoxTask && state.origAiBoxTask) {
      return JSON.stringify(getters.aiBoxTask.config) !== JSON.stringify(state.origAiBoxTask.config)
    }
    return false
  },
  isNoteModified(state, getters) {
    if (getters.aiBoxTask && state.origAiBoxTask) {
      return getters.aiBoxTask.notice !== state.origAiBoxTask.notice || 
        getters.aiBoxTask.notifyFilterMode !== state.origAiBoxTask.notifyFilterMode
    }
    return false
  },
  isSubscribersModified(state, getters) {
    if (getters.aiBoxTask && state.origAiBoxTask) {
      if (getters.aiBoxTask.subscribers.length !== state.origAiBoxTask.subscribers.length) return true
      const origSubIds = state.origAiBoxTask.subscribers.map(subscriber => subscriber.userId)
      const currentSubIds = getters.aiBoxTask.subscribers.map(subscriber => subscriber.userId)
      const addIds = currentSubIds.filter(subscriber => !origSubIds.includes(subscriber))
      const minusIds = origSubIds.filter(subscriber => !currentSubIds.includes(subscriber))
      return addIds.length > 0 || minusIds.length > 0
    }
    return false
  },
  isAiboxModified(state, getters) {
    if (getters.aiBoxTask && state.origAiBoxTask) {
      return state.origAiBoxTask.aiboxId !== getters.aiBoxTask.aiboxId
    }
    return false
  },
  isModified(state, getters) {
    return state.taskId === -99 || 
           getters.isConfigModified || 
           getters.isNoteModified || 
           getters.isSubscribersModified ||
           getters.isAiboxModified ||
           state.isWebhookModified
  }
}

function fillMissingConfig(config, refConfig) {
  const origKeys = getDeepKeys(config)
  for (let key in refConfig) {
    if (config[key] === undefined) {
      config[key] = refConfig[key]
    } else if (typeof config[key] === 'object') {
      fillMissingConfig(config[key], refConfig[key])
    }
  }

  const newKeys = getDeepKeys(config)
  const missingTags = newKeys.filter(key => !origKeys.includes(key))
  return missingTags
}

export default {
namespaced: true,
state,
mutations,
actions,
getters,  
}
import Vue from 'vue'
import i18n from "@/i18n/i18n"
import {
  apiGetBoviaLprEventsAdvancedCount, 
  apiGetBoviaLprEventsAdvanced,
} from '@/api/index.js'
import crypto from '@/utils/crypto'
import { handleError } from '@/utils/handleError.js'

const initialState = () => ({
  // 暫存最新三筆案由於localStorage
  queryCauses: localStorage.getItem('queryCauses') 
    ? JSON.parse(crypto.decrypt(localStorage.getItem('queryCauses'))) : [], // 案由
  searchLoading: false,
  pageSize: 100, // 事件列表每個分頁事件數量
  eventTotal: 0, // 搜尋的總事件數
  currentPage: 1, // 事件列表目前分頁所在頁數，從1開始
  currentIndex: 0, // 事件在事件列表目前分頁的index, 從0開始
  focusIndex: -1, // 點擊事件卡片的index，初始值設為-1, 為了watch其變為０時也能更新focus
  eventList: [], // 僅存放該次搜尋的事件ex:100筆
  queryParams: {}, // 取得SOS事件參數
  beforeEventId: null, // 記錄目前分頁before event id, 為該分頁最小的id
  afterEventId: null, // 記錄目前分頁after event id, 為該分頁最大的id

  queryNumber: localStorage.getItem('queryNumber') ? JSON.parse(crypto.decrypt(localStorage.getItem('queryNumber'))) : [], // 車號

  isReadyExport: false, // 是否可進行一般查詢的匯出，按匯出後要設為false，重新查詢後才能再次匯出
})

const state = initialState()

const getters = {
  currentEvent(state) {
    return state.eventList[state.currentIndex]
  },
  totalEventNo(state) {
    // 目前事件在所有事件的編號，從１開始
    return (state.currentPage - 1) * state.pageSize + state.currentIndex + 1
  }
}

const mutations = {
  resetState(state) {
    const initial = initialState()
    Object.keys(initial).forEach(key => { state[key] = initial[key] })
  },
  getQueryCauses(state, mode) {
    const item = mode === 'chased' ? 'queryCauses' : 'queryCausesSos'
    state.queryCauses = localStorage.getItem(item) 
      ? JSON.parse(crypto.decrypt(localStorage.getItem(item))) : []
  },
  updateSearchLoading(state, value) {
    state.searchLoading = value
  },
  updatePageSize(state, value) {
    state.pageSize = value
  },
  updateEventTotal(state, value) {
    state.eventTotal = value
  },
  updateCurrentPage(state, value) {
    state.currentPage = value
  },
  updateCurrentIndex(state, value) {
    state.currentIndex = value
  },
  updateFocusIndex(state, value) {
    state.focusIndex = value
  },
  updateEventList(state, list) {
    state.eventList = list
    // add uid for each event
    state.eventList.forEach(event => {
      event.uid = `parking-${event.id}`
    })
  },
  updateQueryParams(state, payload) {
    state.queryParams = payload
  },
  updateBeforeEventId(state, value) {
    state.beforeEventId = value
  },
  updateAfterEventId(state, value) {
    state.afterEventId = value
  },
  addQueryNumber(state, number) {
    let idx = state.queryNumber.findIndex(item => item === number.trim())
    if (idx === -1) {
      state.queryNumber.unshift(number.trim())
      state.queryNumber = state.queryNumber.slice(0, 3) // 暫存最新三筆
      localStorage.setItem('queryNumber', crypto.encrypt(JSON.stringify(state.queryNumber)))
    }
  },
  updateIsReadyExport(state, value) {
    state.isReadyExport = value
  },
}

const actions = {
  async searchEvents({ state, commit, dispatch }, payload) {
    commit('updateSearchLoading', true)
    commit('updateCurrentIndex', 0)
    commit('updateFocusIndex', -1)
    // commit('updateCurrentPage', 1) // 重新搜尋切換到分頁１
    // if (state.queryParams.purpose)
      // commit('addQueryCause', state.queryParams.purpose, state.urgMode) // 將案由暫存至localStorage

    if (state.queryParams.content) 
      commit('addQueryNumber', state.queryParams.content) // 將車號暫存至localStorage
    
    const params = { ...state.queryParams, limit: state.pageSize }

    let bGoPrev = false // 是否回到前一分頁
    if (payload.page !== 1) {
      if (payload.page > state.currentPage) {
        delete params.afterId
        params.beforeId = state.beforeEventId
      } else {
        delete params.beforeId
        params.afterId = state.afterEventId
        bGoPrev = true
      }  
    }

    // 因為搜尋參數中, 若為陣列，則需要將搜尋的參數再處理
    let urlSearchParams = new URLSearchParams()
    Object.entries(params).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(item => {
          urlSearchParams.append(key, item)
        })
      } else
        urlSearchParams.append(key, value)
    })

    // 取得事件
    try {
      let res = null
      commit('updateEventList', [])
      res = await apiGetBoviaLprEventsAdvanced(urlSearchParams)
      
      const len = res.data?.length
      if (len > 0) {
        // 若用afterId取資料id會從小到大，須先排序
        res.data.sort((x, y) => y.id - x.id)
        // 資料id由大到小
        commit('updateEventList', res.data)
        commit('updateBeforeEventId', res.data[len - 1].id) // 該分頁最小id
        commit('updateAfterEventId', res.data[0].id) // 該分頁最大id
        commit('updateCurrentPage', payload.page)

        // 若是回到前一分頁，eventIndex更新為前一分頁最後一筆，下一頁則是第一筆
        let eventIndex = bGoPrev ? len - 1 : 0 
        commit('updateCurrentIndex', eventIndex)
        commit('updateFocusIndex', eventIndex)
      } else {
        Vue.prototype.$notify.warning({
          title: i18n.t('search_hint')/*提示*/,
          message: i18n.t('search_hint_nodata')/* 查無資料 */
        })
      }
    } catch (error) {
      handleError(error)
      dispatch('resetData')
    } finally {
      commit('updateSearchLoading', false)
    }

    // 取得事件總數(當取第一頁並且不是回上頁，則不呼叫)
    try {
      if (payload.page === 1 && !bGoPrev) {
        commit('updateEventTotal', 0)
        const resTotal = await apiGetBoviaLprEventsAdvancedCount(urlSearchParams)
        commit('updateEventTotal', resTotal?.data?.total ? resTotal.data.total : 0)
      }
    } catch (err) {
      console.log('apiGetBoviaLprEventsAdvancedCount error: ', err)
    }
  },
  resetData({ commit }) {
    commit('updateEventList', [])
    commit('updateEventTotal', 0) // 搜尋的總事件數
    commit('updateCurrentPage', 1) // 事件列表目前分頁所在頁數
    commit('updateCurrentIndex', 0) // 事件在事件列表目前分頁的index
    commit('updateFocusIndex', -1) // focus事件在事件列表目前分頁的index
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}